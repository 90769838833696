<template>
    <div class="auth-content">
        <div class="auth-modal">
            <img src="@/assets/logo.png" width="230" alt="logo" />
            <hr />
            <input
                v-model="user.email"
                name="email"
                type="text"
                placeholder="E-mail"
            />
            <input
                v-model="user.password"
                v-on:keyup.13="signin"
                name="password"
                type="text"
                v-mask="['##.###.###/####-##']"
                placeholder="CNPJ"
            />
            <input
                v-if="showSignup"
                 v-mask="['##.###.###/####-##']"
                v-model="user.confirmPassword"
                placeholder="Confirme o CNPJ"
                type="text"
            />
            <button v-if="showSignup" @click="signup">Registrar</button>
            <button v-else @click="signin">Entrar</button>

            <a href @click.prevent="showSignup = !showSignup">
                <span v-if="showSignup">Já tem login? Acesse o login!</span>
                <span v-else>Não tem cadastro? Registre-se aqui!</span>
            </a>
        </div>
    </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import { mask } from 'vue-the-mask'

export default {
    name: 'Auth',
    directives: { mask },
    data: function() {
        return {
            showSignup: false,
            user: {}
        }
    },
    methods: {
        signin() {
            axios
                .post(`${baseApiUrl}/signin`, this.user)
                .then(res => {
                    this.$store.commit('setUser', res.data)
                    localStorage.setItem(userKey, JSON.stringify(res.data))
                    this.$router.push({ path: '/' })
                })
                .catch(showError)
        },
        signup() {
            axios
                .post(`${baseApiUrl}/signup`, this.user)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.user = {}
                    this.showSignup = false
                })
                .catch(showError)
        }
    }
}
</script>

<style>
.auth-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
}
.auth-modal {
    width: 400px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.auth-title {
    font-size: 1.5rem;
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 10px;
}

.auth-modal input {
    text-align: center;
    text-decoration: none;
    border: 1px solid #bbb;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    outline: none;
}

.auth-modal button {
    align-self: center;
    background: linear-gradient(to left, #4f0281, #03001e);
    border: 1px solid #bbb;
    color: #fff;
    width: 100%;
    font-size: 15px;
    padding: 10px;
    border-radius: 10px;
    margin: 5px 20px 40px 20px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
}

.auth-modal button:hover {
    background: linear-gradient(to right, #686c6e, #a6a8aa);
}

.auth-modal a {
    margin-top: 15px;
    display: block;
    color: #1a2980;
    font-size: 0.8rem;
    text-decoration: none;
    font-weight: bold;
}

.auth-modal a:hover {
    color: #000;
    text-decoration: none;
}

.auth-modal hr {
    border: 0;
    width: 100%;
    height: 1px;
}
</style>
