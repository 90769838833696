var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"type_document_unique-admin"},[_c('b-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode === 'save' || _vm.mode === 'remove' || _vm.mode === 'read'),expression:"mode === 'save' || mode === 'remove' || mode === 'read'"}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.type_document_unique.id),expression:"type_document_unique.id"}],attrs:{"id":"type_document_unique-id","type":"hidden"},domProps:{"value":(_vm.type_document_unique.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.type_document_unique, "id", $event.target.value)}}}),_c('b-row',[_c('b-col',{attrs:{"md":"5","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição:","label-for":"type_document_unique-name_type_document_unique"}},[_c('b-form-input',{ref:"searchFocus",attrs:{"id":"type_document_unique-name_type_document_unique","type":"text","size":"sm","readonly":_vm.mode === 'remove' ||
                                _vm.mode === 'read' ||
                                _vm.mode === 'insert',"placeholder":"Descrição..."},model:{value:(_vm.type_document_unique.name_type_document_unique),callback:function ($$v) {_vm.$set(_vm.type_document_unique, "name_type_document_unique", $$v)},expression:"type_document_unique.name_type_document_unique"}})],1)],1),_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Reduzido:","label-for":"type_document_unique-initial_type_document_unique"}},[_c('b-form-input',{attrs:{"id":"type_document_unique-initial_type_document_unique","type":"text","required":"","size":"sm","readonly":_vm.mode === 'remove' ||
                                _vm.mode === 'read' ||
                                _vm.mode === 'insert',"placeholder":"Reduzido..."},model:{value:(_vm.type_document_unique.initial_type_document_unique),callback:function ($$v) {_vm.$set(_vm.type_document_unique, "initial_type_document_unique", $$v)},expression:"type_document_unique.initial_type_document_unique"}})],1)],1),_c('b-col',{attrs:{"md":"3","sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.type_document_unique.blocked),expression:"type_document_unique.blocked"}],staticClass:"form-check-input",attrs:{"true-value":"1","false-value":"0","type":"checkbox","id":"type_document_unique-blocked","disabled":_vm.mode === 'remove' ||
                                    _vm.mode === 'read' ||
                                    _vm.mode === 'insert'},domProps:{"checked":Array.isArray(_vm.type_document_unique.blocked)?_vm._i(_vm.type_document_unique.blocked,null)>-1:_vm._q(_vm.type_document_unique.blocked,"1")},on:{"change":function($event){var $$a=_vm.type_document_unique.blocked,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.type_document_unique, "blocked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.type_document_unique, "blocked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.type_document_unique, "blocked", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"type_document_unique-blocked"}},[_vm._v(" Bloqueado? ")])])])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"3","sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.type_document_unique.required),expression:"type_document_unique.required"}],staticClass:"form-check-input",attrs:{"true-value":"1","false-value":"0","type":"checkbox","id":"type_document_unique-required","disabled":_vm.mode === 'remove' ||
                                    _vm.mode === 'read' ||
                                    _vm.mode === 'insert'},domProps:{"checked":Array.isArray(_vm.type_document_unique.required)?_vm._i(_vm.type_document_unique.required,null)>-1:_vm._q(_vm.type_document_unique.required,"1")},on:{"change":function($event){var $$a=_vm.type_document_unique.required,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.type_document_unique, "required", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.type_document_unique, "required", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.type_document_unique, "required", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"type_document_unique-required"}},[_vm._v(" Obrigatório? ")])])])]),_c('b-col',{attrs:{"md":"3","sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.type_document_unique.has_date),expression:"type_document_unique.has_date"}],staticClass:"form-check-input",attrs:{"true-value":"1","false-value":"0","type":"checkbox","id":"type_document_unique-has_date","disabled":_vm.mode === 'remove' ||
                                    _vm.mode === 'read' ||
                                    _vm.mode === 'insert'},domProps:{"checked":Array.isArray(_vm.type_document_unique.has_date)?_vm._i(_vm.type_document_unique.has_date,null)>-1:_vm._q(_vm.type_document_unique.has_date,"1")},on:{"change":function($event){var $$a=_vm.type_document_unique.has_date,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.type_document_unique, "has_date", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.type_document_unique, "has_date", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.type_document_unique, "has_date", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"type_document_unique-register"}},[_vm._v(" Exige Data de Validade? ")])])])]),_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Index:","label-for":"type_document_unique-index_order"}},[_c('b-form-input',{attrs:{"id":"type_document_unique-index_order","type":"text","required":"","size":"sm","readonly":_vm.mode === 'remove' ||
                                _vm.mode === 'read' ||
                                _vm.mode === 'insert',"placeholder":"Index..."},model:{value:(_vm.type_document_unique.index_order),callback:function ($$v) {_vm.$set(_vm.type_document_unique, "index_order", $$v)},expression:"type_document_unique.index_order"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","sm":"12"}},[_c('b-form-textarea',{attrs:{"id":"type_document_unique-description_type_document_unique","placeholder":"Detalhamento..","rows":"3","max-rows":"6","readonly":_vm.mode === 'remove' ||
                                _vm.mode === 'read' ||
                                _vm.mode === 'insert'},model:{value:(_vm.type_document_unique.description_type_document_unique),callback:function ($$v) {_vm.$set(_vm.type_document_unique, "description_type_document_unique", $$v)},expression:"type_document_unique.description_type_document_unique"}})],1)],1),_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Url de Acesso:","label-for":"type_document_unique-url_type_document_unique"}},[_c('b-form-input',{attrs:{"id":"type_document_unique-url_type_document_unique","type":"text","size":"sm","readonly":_vm.mode === 'remove' ||
                                _vm.mode === 'read' ||
                                _vm.mode === 'insert',"placeholder":"Url de Acesso..."},model:{value:(_vm.type_document_unique.url_type_document_unique),callback:function ($$v) {_vm.$set(_vm.type_document_unique, "url_type_document_unique", $$v)},expression:"type_document_unique.url_type_document_unique"}})],1)],1),_c('b-col',{attrs:{"md":"8","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Arquivo Modelo:","label-for":"type_document-model_attach_id_type_document_unique"}},[_c('b-form-file',{staticClass:"mt-4 mb-4",attrs:{"accept":".docx","plain":"","size":"sm"},on:{"input":_vm.saveImagem},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xs":"12"}},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode !== 'save' && _vm.mode !== 'remove'),expression:"mode !== 'save' && mode !== 'remove'"}],staticClass:"mt-2 mb-2 fa fa-plus",attrs:{"title":"Adicionar","size":"sm","variant":"dark"},on:{"click":_vm.alterModo}}),(_vm.mode === 'save')?_c('b-button',{staticClass:"mt-2 mb-2 ml-2 fa fa-save",attrs:{"title":"Salvar","size":"sm","variant":"dark"},on:{"click":_vm.save}}):_vm._e(),(_vm.mode === 'remove')?_c('b-button',{staticClass:"mt-2 mb-2 ml-2 fa fa-trash",attrs:{"title":"Excluir","size":"sm","variant":"dark"},on:{"click":_vm.remove}}):_vm._e(),(
                    _vm.mode === 'remove' || _vm.mode === 'save' || _vm.mode === 'read'
                )?_c('b-button',{staticClass:"mt-2 mb-2 ml-2 fa fa-reply",attrs:{"title":"Voltar","size":"sm","variant":"dark"},on:{"click":_vm.reset}}):_vm._e()],1)],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode !== 'save' && _vm.mode !== 'remove'),expression:"mode !== 'save' && mode !== 'remove'"}]},[_c('div',{staticClass:"col-sm-6"},[_c('b-form-group',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"size":"lg","type":"text","icon":"search","placeholder":"Informe a descrição"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])],1),_c('transition',{attrs:{"name":"slide","type":"animation","appear":""}},[_c('b-table',{key:"slide",staticClass:"table-responsive",attrs:{"busy":_vm.isBusy,"hover":"","head-variant":"light","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.filteredList,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-info my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Carregando...")])],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"title":"Editar","size":"sm","variant":"light"},on:{"click":function($event){return _vm.loadtype_document_unique(data.item)}}},[_c('i',{staticClass:"fa fa-pencil"})])]}}])})],1),_c('b-pagination',{attrs:{"per-page":_vm.perPage,"total-rows":_vm.rows},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }