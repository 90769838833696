<template>
    <div class="page-title">
       <h1 class="ml-2"><i v-if="icon" :class="icon"  class="mr-2" ></i>{{main}} <h2 class="ml-4" >{{sub}}</h2> 
           </h1> 
       
    </div>
</template>

<script>
export default {
    name: "PageTitle",
    props: ['icon','main','sub']
}
</script>

<style>
    .page-title{
        margin: 5px 5px;
        margin-bottom: 2px;
        background:#fff;
        border-radius: 1px;
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
        align-items: left;
    }
    .page-title h1{
        font-size: 1.2rem;
        color:#2c3e50;
    }
    .page-title h2{
        color:#2c3e50;
        margin-top: 5px;
        font-size: 0.8rem;
        padding-bottom: 10px;
    }
</style>
