<template>
    <div class="user-dropdown">
        <div class="user-button">
            <span class="d-none d-sm-block">
                <div v-if="user && user.email && compan">
                    {{ compan.nameBuild }} / {{ user.email }}
                </div>
                <div v-else-if="user && user.email">{{ user.email }}</div>
            </span>
            <i class="fa fa-angle-down ml-2"></i>
        </div>
        <div class="user-dropdown-content">
            <!--b-btn
                size="sm"
                @click.prevent="loadCompanys"
                v-b-popover.hover="'Selecione o Contrato!'"
                >Contrato</b-btn-->
            <router-link to="/admin" v-if="user && user.admin">
                <i class="fa fa-cogs"></i> Administração
            </router-link>
            <a href @click.prevent="logout">
                <i class="fa fa-sign-out"></i> Sair
            </a>
        </div>
        <b-modal
            id="modalCompany"
            ref="modal"
            title-tag="h6"
            title="Informe a Empresa"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            @ok="setCompany"
        >
            <b-form-select
                id="company-id"
                :options="companys"
                v-model="company.id"
            />
        </b-modal>
    </div>
</template>
<script>
import { userKey, companykey, searchglobalkey } from '@/global'
import { mapState } from 'vuex'
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'UserDropdown',
    data: function() {
        return {
            company: {},
            companys: [],
            usernew: {},
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark'
        }
    },
    components: {},
    computed: mapState(['user', 'compan']),
    methods: {
        logout() {
            localStorage.removeItem(userKey)
            localStorage.removeItem(companykey)
            localStorage.removeItem(searchglobalkey)
            this.$store.commit('setUser', null)
            this.$store.commit('setCompan', null)
            this.$store.commit('setSearchGlobal', null)
            this.$router.push({ name: 'auth' })
        },
        setCompany() {
            axios
                .post(`${baseApiUrl}/setCompany`, this.company)
                .then(res => {
                    localStorage.removeItem(companykey)
                    this.$store.commit('setCompan', res.data)
                    localStorage.setItem(companykey, JSON.stringify(res.data))
                    this.hideModal()
                    this.$router.push({ name: 'EmployeeSearch' })
                })
                .catch(showError)
        },
        async loadCompanys() {
            const url = `${baseApiUrl}/companybycompanys/?email=${this.user.email}&register_number=${this.user.register_number}&user_id=${this.user.id}`
            await axios
                .get(url)
                .then(res => {
                    this.companys = res.data.map(company => {
                        return {
                            ...company,
                            value: company.id,
                            text:
                                company.name_build_company +
                                '-' +
                                company.contact_number_company
                        }
                    })
                })
                .finally(() => {
                   // this.openModalCompany()
                   // this.$router.push({ path: '/' })
                })
        },
        openModalCompany() {
            this.$bvModal.show('modalCompany')
        },
        hideModal() {
            this.$bvModal.hide('modalCompany')
        }
    }
}
</script>

<style>
.user-dropdown {
    position: relative;
    height: 100%;
    font-size: 0.7rem;
}

.user-dropdown button,
popover {
    font-size: 0.7rem;
}

.user-dropdown select {
    font-size: 0.7rem;
}

.user-button {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 100;
    height: 100%;
    padding: 0px 20px;
    font-size: 0.8rem;
}

#modalCompany select {
    font-size: 0.7rem;
}
#modalCompany button {
    font-size: 0.7rem;
}

.user-dropdown:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.user-dropdown-img {
    margin: 0px 10px;
}

.user-dropdown-img > img {
    max-height: 37px;
    border-radius: 5px;
}

.user-dropdown-content {
    position: absolute;
    right: 0px;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.user-dropdown:hover .user-dropdown-content {
    visibility: visible;
    opacity: 1;
}

.user-dropdown-content a {
    text-decoration: none;
    color: #000;
    padding: 10px;
}

.user-dropdown-content a:hover {
    text-decoration: none;
    color: #000;
    background-color: #ededed;
}
</style>
