<template>
    <div class="type_document_company_size-admin">
        <b-form
            v-show="mode === 'save' || mode === 'remove' || mode === 'read'"
        >
            <input
                id="type_document_company_size-id"
                type="hidden"
                v-model="type_document_company_size.id"
            />
            <b-row>
                     <b-col md="6" sm="12">
                        <b-form-group
                                label="Porte da Empresa:"
                                label-for="type_document_company_size-company_size_id"
                            >
                                <b-form-select
                                    id="type_document_company_size-company_size_id"
                                    size="sm"
                                    :options="company_sizes"
                                      :disabled="
                                            mode === 'remove' ||
                                                mode === 'read' ||
                                                mode === 'insert'
                                        "
                                    v-model=" type_document_company_size.company_size_id"
                                />
                            </b-form-group>
                     </b-col>
                    <b-col md="6" sm="12">
                        <b-form-group
                                label="Tipo de Documento:"
                                label-for="type_document_company_size-type_document_id"
                            >
                                <b-form-select
                                    id="type_document_company_size-type_document_id"
                                    size="sm"
                                    :options="company_sizes"
                                      :disabled="
                                            mode === 'remove' ||
                                                mode === 'read' ||
                                                mode === 'insert'"
                                    v-model=" type_document_company_size.type_document_id"
                                />
                            </b-form-group>
                     </b-col>
            </b-row>
            <b-row>
                 <b-col md="3" sm="12">
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="type_document_company_size.require_register"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                id="type_document_company_size-require_register"
                                :disabled="
                                    mode === 'remove' ||
                                        mode === 'read' ||
                                        mode === 'insert'
                                "
                            />
                            <label
                                class="form-check-label"
                                for="type_document_company_size-require_register"
                            >
                                Obrigatório?
                            </label>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-form>
        <b-row>
            <b-col xs="12">
                <b-button
                    title="Adicionar"
                    size="sm"
                    class="mt-2 mb-2 fa fa-plus"
                    variant="dark"
                    v-show="mode !== 'save' && mode !== 'remove'"
                    @click="alterModo"
                ></b-button>
                <b-button
                    title="Salvar"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-save"
                    v-if="mode === 'save'"
                    @click="save"
                ></b-button>
                <b-button
                    title="Excluir"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-trash"
                    v-if="mode === 'remove'"
                    @click="remove"
                ></b-button>
                <b-button
                    title="Voltar"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-reply"
                    v-if="
                        mode === 'remove' || mode === 'save' || mode === 'read'
                    "
                    @click="reset"
                ></b-button>
            </b-col>
        </b-row>
        <b-row v-show="mode !== 'save' && mode !== 'remove'">
            <div class="col-sm-6">
                <b-form-group
                        label="Porte da Empresa:"
                        label-for="company_size_id"
                    >
                        <b-form-select
                            id="company_size_id"
                            size="sm"
                            @input="loadTypeDocumentCompanySizes(company_size_id)"
                            :options="company_sizes"
                            v-model=" company_size_id"
                        />
                </b-form-group>
                <b-form-group>
                    <input
                        size="lg"
                        type="text"
                        icon="search"
                        v-model="search"
                        placeholder="Informe a descrição"
                        class="form-control"
                    />
                </b-form-group>
            </div>
            <transition name="slide" type="animation" appear>
                <b-table
                    :busy="isBusy"
                    class="table-responsive"
                    hover
                    key="slide"
                    head-variant="light"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="filteredList"
                    :fields="fields"
                >
                    <template v-slot:table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Carregando...</strong>
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            title="Editar"
                            size="sm"
                            variant="light"
                            @click="loadtype_document_company_size(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-pencil"></i>
                        </b-button>
                    </template>
                </b-table>
            </transition>
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="rows"
            >
            </b-pagination>
        </b-row>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'TypeDocumentCompanySizeAdmin',

    data: function() {
        return {
            mode: 'insert',
            type_document_company_size: {},
            type_document_company_sizes: [],
            company_sizes:[],
            type_documents: [],
            company_size_id: null,
            search: '',
            showTable: false,
            showSearch: true,
            isBusy: false,
            currentPage: 1,
            perPage: 5,
            fields: [
                { key: 'id', label: 'Código', sortable: true },
                {
                    key: 'name_type_document',
                    label: 'Descrição',
                    sortable: true
                },
                {
                    key: 'initial_type_document',
                    label: 'Reduzido',
                    sortable: false
                },
                {
                    key: 'require_register',
                    label: 'Obrigatório',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                { key: 'actions', label: 'Ações', variant: 'secondary' }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        filteredList() {
            return this.type_document_company_sizes.filter(type_document_company_size => {
                return type_document_company_size.name_type_document
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
            })
        },
        icon() {
            return this.showTable ? 'fa-retweet' : 'fa-search'
        },
        rows() {
            return this.type_document_company_sizes.length
        }
    },
    methods: {
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        alterModo() {
            this.mode = 'save'
            this.type_document_company_size = {}
            this.$refs.searchFocus.focus()
        },
        save() {
            const method = this.type_document_company_size.id ? 'put' : 'post'
            const id = this.type_document_company_size.id ? `/${this.type_document_company_size.id}` : ''
            axios[method](
                `${baseApiUrl}/typedocuments${id}`,
                this.type_document_company_size
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadTypeDocumentCompanySizes(item) {
            const url = `${baseApiUrl}/typedocumentcompanysizesbase/${item}`
            axios.get(url).then(res => {
                this.type_document_company_sizes = res.data
                this.isBusy = false
            })
        },
        reset() {
            this.mode = 'insert'
            this.loadTypeDocumentCompanySizes(this.company_size_id)
        },
        remove() {
            const id = this.type_document_company_size.id
            axios
                .delete(`${baseApiUrl}/typedocuments/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadtype_document_company_size(type_document_company_size, mode = 'save') {
            this.mode = mode
            this.type_document_company_size = { ...type_document_company_size }
            this.$refs.searchFocus.focus()
        },
       async  loadTypeDocuments() {
            const url = `${baseApiUrl}/typedocuments`
         await   axios.get(url).then(res => {
                this.type_documents = res.data.map(type_document => {
                    return {
                        value: type_document.id,
                        text: type_document.name_type_document
                    }
                })
            })
        },
        loadCompanySize() {
            const url = `${baseApiUrl}/companysizes`
             axios
                .get(url)
                .then(res => {
                    this.company_sizes = res.data.map(street_types => {
                        return {
                            value: street_types.id,
                            text:
                                street_types.name_company_size 
                        }
                    })
                })
        },
    },
    mounted() {
        this.loadTypeDocuments()
        this.loadCompanySize()
        this.showTable = true
    }
}
</script>

<style>
.type_document_company_size-admin select {
    font-size: 0.75rem;
}
.type_document_company_size-admin input {
    font-size: 0.75rem;
}
.type_document_company_size-admin button {
    font-size: 0.75rem;
}
.type_document_company_size-admin table {
    font-size: 0.75rem;
}

@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
