<template>
        <div class="registeremployeedoc">
        <PageTitle
            icon="fa fa-clone"
            main="Registro de Documento(s)"
           :sub = company_employee.name_employee
        />
            <b-card bg-variant="light" text-variant="dark" title="">
                <b-row>
                    <b-col md="12" sm="12">
                        <b-table
                            hover
                            head-variant="ligth"
                            :items="company_employee_files"
                            :fields="fieldsFile"
                            >
                            <template #cell(url_type_document_monthly)="data">
                                <a @click="openLink(data.value)" :href="`#${data.value.replace(/[^a-z]+/i,'-')}`">{{ data.value }}</a>
                            </template>
                            <template v-slot:cell(documents)="data">
                                <b-button
                                    title="Documento Auxiliar"
                                    size="sm"
                                    variant="outline-secondary"
                                    v-if="data.item.model_attach_id_type_document"
                                    @click="openLinkDocument(data.item.url_file)"
                                    class="mr-2 mt-2"
                                >
                                    <i class="fa fa-file-word-o"></i>
                                    Documento Auxiliar
                                </b-button>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <!--b-button
                                    title="Link"
                                    size="sm"
                                    variant="outline-primary"
                                    v-if="data.item.url_type_document"
                                    @click="openLink(data.item.url_type_document)"
                                    class="mr-2 mt-2"
                                >
                                    <i class="fa fa-link"></i>
                                    Link
                                </b-button!-->
                                
                                    <b-button
                                        title="Adicionar Arquivo"
                                        size="sm"
                                        v-if="!data.item.id_documento > 0"
                                        variant="outline-success"
                                        @click="[loadCompanyEmployeeFile(data.item),openModalFile()]"
                                        class="mr-2 mt-2"
                                    >
                                        <i class="fa fa-plus"></i>
                                        Adicionar
                                    </b-button>
                                    <b-button
                                        title="Visualizar"
                                        size="sm"
                                        variant="outline-dark"
                                        v-if="data.item.id_documento"
                                        @click="
                                            ;[
                                                loadCompanyEmployeeFile(data.item),
                                                openLinkView(data.item.url_file_doc)
                                            ]
                                        "
                                        class="mr-2 mt-2"
                                    >
                                        <i class="fa fa-file-pdf-o"></i>
                                        Visualizar
                                    </b-button>
                                        <b-button
                                        title="Editar Arquivo"
                                        size="sm"
                                        v-if="data.item.id_documento"
                                        variant="outline-warning"
                                        @click="[loadCompanyEmployeeFile(data.item),openModalFileEdit()]"
                                        class="mr-2 mt-2"
                                    >
                                        <i class="fa fa-pencil"></i>
                                        Editar
                                    </b-button>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                </b-card>
                <b-modal
                    id="modalPDF"
                    ref="modal"
                    title-tag="h6"
                    centered
                    size="lg"
                    ok-only
                    title="Visualização do Documento"
                >
                    <div>
                        <pdf :src="company_employee_file.url_file_doc"></pdf>
                    </div>
                </b-modal>
         <b-modal
             v-model="modalFileShow"
            id="modalEmployeeFileDoc"
            title-tag="h6"
            centered
            size="lg"
            title="Registro Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
            <b-row>
                 <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
          <b-modal
             v-model="modalFileShowEdit"
            id="modalFileEditDoc"
            title-tag="h6"
            centered
            size="lg"
            title="Editar Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
            <b-row>
                <b-col md="12" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShowEdit=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
        </div>
</template>
<script>

import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import pdf from 'vue-pdf'

export default {
    name: 'RegisterEmployeeDoc',
    props: ['company_employee'],
    components: { PageTitle,pdf},
    data: function(){
        return{
                companys: [],
                company_employee_files: [],
                company_employees:[],
                company_employee_file:{},
                company:{},
                storage_file: {},
                request_accept:false,
                file_upload_table: false,
                file_doc_show: true,
                file_upload_show: false,
                modalFileShow: false,
                modalFileShowEdit:false,
                file: null,
                fieldsFile: [
                {
                    key: 'name_type_document',
                    label: 'Documento',
                     variant: 'light',
                      thStyle: { width: "15%" },
                    sortable: true
                },
                {
                    key: 'description_type_document',
                    label: 'Detalhamento',
                     variant: 'light',
                       thStyle: { width: "40%" },
                    sortable: false
                },
                {
                    key: 'url_type_document_monthly',
                    label: 'Link',
                     variant: 'light',
                         thStyle: { width: "15%" },
                    sortable: false
                }, 
                { key: 'documents', label: '', variant: 'light' ,thStyle: { width: "10%" }},       
                {
                    key: 'name_file_doc',
                    label: 'Arquivo Anexado',
                     variant: 'secondary',
                     thStyle: { width: "10%" },
                    sortable: false
                },
                { key: 'actions', label: '', variant: 'light' ,thStyle: { width: "10%" }},
                ]
        }
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
     methods: {

            async loadCompanyEmployeeFiles() {
                const url = `${baseApiUrl}/typedocumentsemployeedocuments/${this.company_employee.id}`
              await  axios(url).then(res => {
                        this.company_employee_files = res.data
                    })
            },
            openModalPDF() {
                this.$bvModal.show('modalPDF')
            },
            openModalFile() {
                this.file_doc_show = false

                this.$bvModal.show('modalEmployeeFileDoc')
            },
            closeModalFile(){
                this.$bvModal.hide('modalEmployeeFileDoc')
            },
            async saveImagem() {

                this.file_upload_show = true
                let formData = new FormData()
                formData.append('file', this.file)
                formData.append('company_id_storage', this.company_employee.company_id_employee)
                formData.append('company_employee_id_storage', this.company_employee.id)
                formData.append('type_document_id_storage', this.company_employee_file.id_type_doc)

                    await axios
                        .post(`${baseApiUrl}/storagefiles`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then( async () => {
                            this.$toasted.global.defaultSuccess()
                            await  this.getLastStorageFile()
                        
                        })
                        .finally(async ()=>{
                            if(this.company_employee_file.storage_file_id > 0){
                            await  this.saveFile()
                            }else{
                                this.$toasted.global.defaultWarning({
                                        msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                        });
                            }
                        })
                        .catch(showError)
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesbylast/?name_file=${this.file.name}&company_id_storage=${this.company_employee.company_id_employee}&company_employee_id_storage=${this.company_employee.id}&type_document_id_storage=${this.company_employee_file.id_type_doc}`
            await axios(url).then(
                res =>
                    (this.company_employee_file = {
                        ...this.company_employee_file,
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        url_ftp: res.data.url_file,
                        company_employee_id: this.company_employee.id,
                        type_document_id_file: this.company_employee_file
                            .id_type_doc
                    })
            )
        },
         async saveFile() {
            
          const method = this.company_employee_file.id ? 'put' : 'post'
            const id = this.company_employee_file.id
                ? `/${this.company_employee_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployeefiles${id}`,
                this.company_employee_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                })
                .finally(async () => {
                    await this.getLastFileTypeDocument()
                    this.file_upload_show = false
                    this.closeModalFile()
                })
                .catch(showError)
        },
         async checkFileFtpById(item) {

             if(item.storage_file_id > 0){

            await axios['put'](
                `${baseApiUrl}/companyemployeefilesftpbyid/${item.id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.modalFileShow = false
                     this.modalFileShowEdit = false
                })
                .finally( () => {
                        this.loadCompanyEmployeeFiles()
                           
                })
                .catch(showError)
             }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }    
          
          
        },
         openLink(item){
            const url = item.replace(" ","")
            window.open(url,'_blank')
        },
        openLinkView(item){
            const url = item
            window.open(url)
        },
        async openLinkDocument(item){
            const url = item
            await axios.get(url)
            window.open(url)
        },
        openModalEmployeeFile() {
            this.$bvModal.show('modalEmployeeFile')
        },
        openModalFileEdit(){
            this.file_doc_show = false
            this.$bvModal.show('modalFileEditDoc')
        },
        loadCompanyEmployeeFile(item) {
            this.company_employee_file = { ...item }
        },
        async getLastFileTypeDocument() {
            const url = `${baseApiUrl}/companyemployeefilesbylast/?company_employee_id=${this.company_employee.id}&type_document_id_file=${this.company_employee_file.type_document_id_file}`
            await axios(url).then(
                res =>
                    (
                        this.checkFileFtpById(res.data)
                    )
            )
        },

     },
    async mounted() {
        await this.loadCompanyEmployeeFiles()
    }
}
</script>

<style>
.registeremployeedoc {
    font-size: 0.75rem;
}

.registeremployeedoc button {
    font-size: 0.75rem;
}

#modalFile {
     font-size: 0.75rem;
}

#modalFile button{
     font-size: 0.75rem;
}

#modalFileEditDoc {
     font-size: 0.75rem;
}

#modalFileEditDoc button {
     font-size: 0.75rem;
}

</style>