<template>
    <div class="accordion" role="tablist">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button @click="loadCompanyMonthlys"  block v-b-toggle.accordion-1 variant="dark">{{ company.name_build_company }} - {{company.contact_number_company}} </b-button>
        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ul>
                    <li v-for="company_monthly in company_monthlys" :key="company_monthly.id"  >
                        <keep-alive>    
                        <ListMonthly :company_monthly= "company_monthly"/>
                        </keep-alive>
                    </li>
                </ul>
            </b-card-body>
        </b-collapse>
      </b-card-header>
        
    </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'
import ListMonthly from './../util/ListMonthly'  

export default {
    name: 'ListCompanyMonthly',
    components: {ListMonthly},
    props: ['company'],
    data: function() {
        return {
            company_monthlys: [],
        
        }
    },
    methods: {
      async  loadCompanyMonthlys() {
          const url = `${baseApiUrl}/companymonthlyreject/${this.company.id}`
            await axios(url).then(res => {
                this.company_monthlys = res.data
            })
        },

    },
    mounted() {
        this.loadCompanyMonthlys()
    }
}
</script>

<style>
.accordion {
    font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}

.accordion ul {
    font-size: 0.75rem;
    list-style-type: none;
}
.accordion input {
    font-size: 0.75rem;
}

</style>
