<template>
    <div class="admin-pages">
        <PageTitle
            icon="fa fa-cogs"
            main="Administração do Sistema"
            sub="Cadastros e Configurações"
        />
        <div class="admin-pages-tabs">
            <b-card title="Card Title" small no-body>
                <b-tabs card>
                    <b-tab active>
                        <template v-slot:title>
                            <i class="fa fa-building"></i>
                            <strong>Empreiteiras</strong>
                        </template>
                        <CompanyAdmin />
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="fa fa-user"></i> <strong>Usuários</strong>
                        </template>
                        <UserAdmin />
                    </b-tab>
                     <b-tab>
                        <template v-slot:title>
                            <i class="fa fa-clipboard"></i>
                            <strong>Tipo de Responsável</strong>
                        </template>
                        <TypeResponsable />
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="fa fa-clipboard"></i>
                            <strong>Tipo de Documento (Funcionário/Responsável)</strong>
                        </template>
                        <TypeDocumentAdmin />
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="fa fa-clipboard"></i>
                            <strong>Documento(s) Porte Empresa  (Funcionário)</strong>
                        </template>
                        <TypeDocumentCompanySizeAdmin />
                    </b-tab>
                     <b-tab>
                        <template v-slot:title>
                            <i class="fa fa-clipboard"></i>
                            <strong>Tipo de Documento (Empresa)</strong>
                        </template>
                        <TypeDocumentUniqueAdmin />
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="fa fa-clipboard"></i>
                            <strong>Tipo de Documento (Mensal)</strong>
                        </template>
                        <TypeDocumentMonthlyAdmin />
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import UserAdmin from './UserAdmin'
import CompanyAdmin from './CompanyAdmin'
import TypeDocumentAdmin from './TypeDocumentAdmin'
import TypeDocumentUniqueAdmin from './TypeDocumentUniqueAdmin'
import TypeDocumentCompanySizeAdmin from './TypeDocumentCompanySizeAdmin'
import TypeDocumentMonthlyAdmin from './TypeDocumentMonthlyAdmin'
import TypeResponsable from './TypeResponsable'


export default {
    name: 'AdminPages',
    components: { PageTitle, UserAdmin, CompanyAdmin, TypeDocumentAdmin,TypeDocumentUniqueAdmin,TypeDocumentCompanySizeAdmin,TypeDocumentMonthlyAdmin,TypeResponsable }
}
</script>

<style>
.admin-pages {
    font-size: 0.75rem;
}
</style>
