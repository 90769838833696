<template>
        <div class="rejectfilemonth">
        <PageTitle
            icon="fa fa-clone"
            main="Documento Rejeitado"
            sub="Mensal"
        />
            <b-card bg-variant="light" text-variant="dark" title="Contrato(s)">
                <b-card-text>
                    Selecione o contrato para apresentar o(s) período(s).
                    </b-card-text>
                    <b-row>
                         <b-col md="3" sm="6">
                            <b-table
                                class="table-responsive"
                                :items="companys"
                                :fields="fieldsCompany"
                                 :select-mode="selectMode"
                                  @row-selected="onRowSelected"
                                  selectable
                                   ref="selectableTable"
                                >
                                 <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">Selecionado</span>
                                    </template>
                                    <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">Não Selecionado</span>
                                    </template>
                                </template>

                            </b-table>
                        
                    </b-col>
                     <b-col md="3" sm="6">
                            <b-table
                                class="table-responsive"
                                :select-mode="selectMode"
                                @row-selected="onRowMonthSelected"
                                  selectable
                                :items="company_monthlys"
                                :fields="fieldsMonth"
                                >
                               <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">Selecionado</span>
                                    </template>
                                    <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">Não Selecionado</span>
                                    </template>
                                </template>
                                <template v-slot:cell(actions)="data">
                                     <b-button
                                            title="Solicitar Autorização"
                                            size="sm"
                                            variant="info"
                                            @click="changeFile(data.item)"
                                            :disabled="request_accept"
                                            class=" mr-2"
                                        >

                                         <div  class="d-flex justify-content-center ">
                                            <b-spinner v-show="request_accept" label="Enviando..."></b-spinner>
                                        </div>
                                        <div v-show="!request_accept">
                                                <i  v-show="!request_accept" class="fa fa-exchange"></i>
                                                Solicitar Autorização
                                         </div>
                                    </b-button>
                                </template>
                            </b-table>
                         </b-col>
                          <b-col md="6" sm="12">
                             <b-table
                                        class="table-responsive"
                                        hover
                                        head-variant="ligth"
                                        :items="company_monthly_files"
                                        :fields="fieldsFile"
                                    >
                                    <template v-slot:cell(actions)="data">
                                            <b-button
                                                title="Visualizar"
                                                size="sm"
                                                variant="outline-dark"
                                                @click="
                                                    ;[
                                                        loadFile(data.item),
                                                        openModalPDF()
                                                    ]
                                                "
                                                class="mr-2 mt-2"
                                            >
                                                <i class="fa fa-file-pdf-o"></i>
                                                Visualizar
                                            </b-button>
                                             <b-button
                                                title="Editar Arquivo"
                                                size="sm"
                                                variant="outline-dark"
                                                @click="[loadFile(data.item),openModalFile()]"
                                                class="mr-2 mt-2"
                                                >
                                                <i class="fa fa-pencil"></i>
                                                Editar
                                            </b-button>
                                             <!--b-button
                                                title="Enviar Arquivo"
                                                size="sm"
                                                v-if="data.item.changed && !data.item.return_document_change_id > 0"
                                                variant="outline-dark"
                                                @click="[showMsgBoxSendFile(data.item)]"
                                                :disabled="file_upload_table"
                                                class="mr-2 mt-2"
                                            >
                                                 <div  class="d-flex justify-content-center ">
                                                    <b-spinner v-show="file_upload_table" label="Enviando..."></b-spinner>
                                                </div>
                                                <div v-show="!file_upload_table">
                                                <i  v-show="!file_upload_table" class="fa fa-cloud-upload"></i>
                                                Enviar
                                                </div>
                                               
                                            </b-button-->
                                            
                                    </template>
                             </b-table>
                          </b-col>
                    </b-row>
                </b-card>
                 <b-modal
                    id="modalPDF"
                    ref="modal"
                    title-tag="h6"
                    centered
                    size="lg"
                    ok-only
                    title="Visualização do Documento"
                >
                    <div>
                        <pdf :src="company_monthly_file.url_file"></pdf>
                    </div>
                </b-modal>
                <b-modal
                    v-model="modalFileShow"
                    id="modalFile"
                    title-tag="h6"
                    centered
                    size="lg"
                    title="Arquivo"
            
                >
            <b-row>
                <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
        </div>
</template>
<script>

import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import pdf from 'vue-pdf'

export default {
    name: 'RejectFileMonth',
    components: { PageTitle,pdf},
    data: function(){
        return{
                selectMode: 'single',
                selected: [],
                selectedMonth: [],
                companys: [],
                company_monthly_files: [],
                company_monthlys:[],
                company_monthly_file:{},
                storage_file: {},
                request_accept:false,
                file_upload_table: false,
                file_doc_show: true,
                file_upload_show: false,
                modalFileShow: false,
                company_monthly: {},
                file: null,
                fieldsCompany: [
                {
                    key:'selected',
                    label:'...'
                },
                {
                    key: 'name_build_company',
                    label: 'Contrato',
                        variant: 'light',
                    sortable: true
                },
                                {
                    key: 'contact_number_company',
                    label: 'Nº Contrato',
                        variant: 'light',
                    sortable: true
                },

                ],
                fieldsMonth: [
                {
                    key:'selected',
                    label:'...'
                },
                {
                    key: 'description_monthly',
                    label: 'Período',
                        variant: 'light',
                    sortable: true
                },
                { key: 'actions', label: '', variant: 'light' },
                ],
                fieldsFile: [
                {
                    key: 'name_type_document_monthly',
                    label: 'Documento',
                     variant: 'light',
                    sortable: true
                },
                {
                    key: 'justify_comments',
                    label: 'Justificativa',
                     variant: 'light',
                    sortable: false
                },
               {
                    key: 'changed',
                    label: 'Alterado',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                { key: 'actions', label: '', variant: 'light' },
                ]
        }
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
     methods: {

            showMsgBoxSendFile(item) {
                this.boxCancel = ''
                this.$bvModal
                .msgBoxConfirm('Deseja enviar o arquivo ?', {
                    title: 'Por Favor Confirme',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'SIM',
                    cancelTitle: 'NÃO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    this.boxCancel = value
                    if (this.boxCancel === true) {
                        this.file_upload_table = true    
                        await this.checkFileFtp(item)
        
                    }
                })
            },
            onRowSelected(items) {
                    this.selected = items
                    let row = this.selected[0]
                     row ? this.loadCompanyMonthlys(row) : this.company_monthlys = []    
            },
            onRowMonthSelected(items) {
                    this.company_monthly ={}
                    this.selectedMonth = items
                    let row = this.selectedMonth[0]
                    this.company_monthly = row
                     row ? this.loadCompanyMonthlyFiles(row) : this.company_monthly_files = []    
            },
            loadCompanyMonthly() {
                const url = `${baseApiUrl}/companysmonthlyrejectuser/${this.user.id}`
                axios(url).then(res => {
                 this.companys = res.data
                })
            },  
            loadCompanyMonthlys(item) {
            const url = `${baseApiUrl}/companymonthlyreject/${item.id}`
                 axios(url).then(res => {
                    this.company_monthlys = res.data
                })
            },
            loadCompanyMonthlyFiles(item) {
                const url = `${baseApiUrl}/documentmonthlyfilesreject/${item.id}`
               axios(url).then(res => {
                        this.company_monthly_files = res.data
                    })
            },
            openModalPDF() {
                this.$bvModal.show('modalPDF')
            },
            openModalFile() {
                this.file_doc_show = false
                this.$bvModal.show('modalFile')
            },
            closeModalFile(){
                this.$bvModal.hide('modalFile')
            },
            loadFile(item) {
                this.company_monthly_file = { ...item }
            },
            async saveImagem() {

            this.file_upload_show = true
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('company_doc_monthly_id_storage', this.company_monthly_file.company_doc_monthly_id)
            formData.append('type_document_monthly_id_storage', this.company_monthly_file.type_documento_monthly_id)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then( async () => {
                    this.$toasted.global.defaultSuccess()
                     await  this.getLastStorageFile()
                   
                })
                .finally(async ()=>{
                    if(this.company_monthly_file.storage_file_id > 0){
                      await  this.saveFile()
                    }else{
                          this.$toasted.global.defaultWarning({
                                msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                });
                    }
                })
                .catch(showError)
        },
         async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesmonthlydbylast/?company_doc_monthly_id_storage=${this.company_monthly_file.company_doc_monthly_id}&type_document_monthly_id_storage=${this.company_monthly_file.type_documento_monthly_id}`
            await axios(url).then(
                res =>
                    {
                        //this.storage_file = res.data
                        
                            this.company_monthly_file = {
                            
                            company_doc_monthly_id: this.company_monthly_file.company_doc_monthly_id,
                            storage_file_id: res.data.id,
                            name_file: res.data.name_file,
                            path_file: res.data.path_file,
                            parent_doc_monthly_id: this.company_monthly.parent_doc_monthly_id,
                            id: this.company_monthly_file.id,
                            status_type_document_file: 2,
                            type_documento_monthly_id: this.company_monthly_file
                            .type_documento_monthly_id
                    }
                    }
                  
            ) 
        },
         async saveFile() {
            
            const method = 'put'
            const id = this.company_monthly_file.id
                ? `/${this.company_monthly_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companydocumentmonthlyfiles${id}`,
                this.company_monthly_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                   
                })
                .finally(async () => {
                   await this.checkFileFtp(this.company_monthly_file)
                     this.closeModalFile()
                   // await this.loadCompanyMonthlyFiles(this.company_monthly)
                    this.file_upload_show = false
                })
                .catch(showError)
        },
        async checkFileFtp(item) {

            this.company_monthly_file = {...item}
            if(this.company_monthly_file.storage_file_id > 0){
                  await axios['put'](
                `${baseApiUrl}/companymonthlyfilesftp/${this.company_monthly_file.id}`,
                this.company_monthly_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                      this.file_upload_table = false
                      this.file_upload_show = false
                })
                .finally( () => {
                     this.loadCompanyMonthlyFiles(this.company_monthly)
                     this.file_upload_table = false   
                        this.file_upload_show = false
                })
                .catch(showError)

                
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }

               this.loadCompanyMonthlyFiles(this.company_monthly)
                   
        },
        async changeFile(item) {           

             this.request_accept = true

                await axios['post'](
                `${baseApiUrl}/companydocumentmonthlyfileschange/${item.id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()  
                       this.request_accept = false   
                          this.loadCompanyMonthly()

                })
                .catch(showError)

                this.request_accept = false  
        },
        /*
        loadCompanyDocumentMonthlyFiles() {
            const url = `${baseApiUrl}/documentmonthlyfilesreject/${this.company_monthly_file.company_doc_monthly_id}`
           axios(url).then(res => {
                this.company_monthly_files = res.data
            })
        },
        */
     },
    async mounted() {
        await this.loadCompanyMonthly()
    }
}
</script>

<style>
.rejectfilemonth {
    font-size: 0.75rem;
}

.rejectfilemonth button {
    font-size: 0.75rem;
}

#modalFile {
     font-size: 0.75rem;
}

#modalFile button{
     font-size: 0.75rem;
}

</style>