<template>
    <div class="accordion">
              <b-row>
      
                <b-button
                        title="Atualizar Lista"
                        size="sm"
                        variant="light"
                        @click="loadLogs()"
                        class="ml-4 mr-2 mt-2 mb-2"
                    >
                        <i class="fa fa-refresh"></i>
                        Atualizar
                </b-button>
              </b-row>
              <b-row>
               
                 <b-table
                    class="table-responsive"
                    hover
                    :busy="isBusy"
                    head-variant="ligth"
                    :items="logs"
                    :fields="fields"
                >
                 <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Carregando...</strong>
                    </div>
                </template>
                 <template v-slot:cell(actions)="data">
                     <b-button
                        title="Visualizar"
                        size="sm"
                        variant="light"
                        @click="openModalLog(data.item)"
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-file-pdf-o"></i>
                        Visualizar
                    </b-button>
                 </template>
                </b-table>
              </b-row>

        <b-modal
            id="modalLogDetail"
            ref="modal"
            title-tag="h6"
            centered
            ok-only
            size="lg"
            title="Log"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            >
            <b-form-textarea
            id="textarea-rows"
            v-model="log.detail"
            rows="6"
            max-rows="8"
            ></b-form-textarea>
         </b-modal>
    </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'
var moment = require('moment')

export default {
    name: 'FormLog',

    props: ['filter'],
    data: function() {
        return {
            log: {},
            logs: [],
             isBusy: false,
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
             fields: [
                { 
                    key: 'register', 
                    label: 'Data Registro', 
                    sortable: true,
                    variant: 'primary',
                   formatter: value => (value ? moment.utc(value).format("DD-MM h:mm:ss") : '') },  
                {
                    key: 'title',
                    label: 'Chamada',
                     variant: 'light',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Ação',
                      variant: 'light',
                    sortable: false
                },
                {
                    key: 'url',
                    label: 'URL',
                     variant: 'light',
                    sortable: false
                },
                { key: 'success', label: 'Sucesso', sortable: false,
                        formatter: value => value ? 'Sim' : 'Não' },
                { key: 'error', label: 'Erro', sortable: false,
                        formatter: value => value ? 'Sim' : 'Não' },
                { key: 'actions', label: 'Ações', variant: 'light' }
            ]
        }
    },
    methods: {
         toggleBusy() {
            this.isBusy = !this.isBusy
        },
      async  loadLogs() {
            this.toggleBusy()
            
            if(this.filter.namelog === "employee"){
                 const url = `${baseApiUrl}/returnlogcompanyemployees/${this.filter.id}`
                    await axios(url).then(res => {
                        this.logs = res.data
                        this.toggleBusy()
                    })
            }  
            
             else if(this.filter.namelog === "monthly"){
                 const url = `${baseApiUrl}/returnlogcompanymonthly/${this.filter.id}`
                    await axios(url).then(res => {
                        this.logs = res.data
                        this.toggleBusy()
                    })
            }   
         
        },
        loadLog(item){
            this.log = {...item}
        },
        openModalLog(item) {
              this.log = {...item}
            this.$bvModal.show('modalLogDetail')
        },

    },
    mounted() {
        this.loadLogs()
    }
}
</script>

<style>
.accordion {
    font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}

.accordion input {
    font-size: 0.75rem;
}

#modalLogDetail {
     font-size: 0.75rem;
}

#modalLogDetail textarea {
     font-size: 0.75rem;
}


#modalLogDetail button {
     font-size: 0.75rem;
}


</style>
