import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        isMenuVisible: true,
        isMenuDisable: true,
        user: null,
        compan: null,
        searchglobal: null
    },
    mutations:{
        toggleMenu(state,isVisible){
            if(!state.user){
                state.isMenuVisible = false
                return
            }    
            if(isVisible === undefined){
                state.isMenuVisible = !state.isMenuVisible
            }else{
                state.isMenuVisible = isVisible
            }
        },
        menuDisabled(state,disabled){
            if(disabled === undefined){
                state.isMenuDisable = !state.isMenuDisable
            }else{
                state.isMenuDisable = disabled
            }
        },
        setUser(state,user){
            state.user = user
            if(user){
                axios.defaults.headers.common['Authorization'] = `bearer ${user.token}` 
                state.isMenuVisible = true
            }else{
               delete axios.defaults.headers.common['Authorization'] 
               state.isMenuVisible = false
            }
        },
        setCompan(state,company){
            state.compan = company
        },
        setSearchGlobal(state,search){
            state.searchglobal = search
        }
    }
})