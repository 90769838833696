<template>
    <div class="home">
        <div class="logo">
            <LoadingProcess/>
        </div>
    </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'
import LoadingProcess from './../util/LoadingProcess.vue'  

export default {
    name: 'Home',
     components: {LoadingProcess},
    computed: {
         compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
    data: function() {
        return { companys: [],
                     employees: [], 
                     files: [], 
                     user_files: [],
                     user_complement:[],
                     user_request:[]
                     }
    },
    
    methods: {
        async loadCompanys() {
            const url = `${baseApiUrl}/companybycompanys/?email=${this.user.email}&register_number=${this.user.register_number}&user_id=${this.user.id}`
            await axios
                .get(url)
                .then(res => {
                    this.companys = res.data.map(company => {
                        return {
                            ...company,
                            value: company.id,
                            text:
                                company.name_build_company +
                                '-' +
                                company.contact_number_company
                        }
                    })
                })

        },
        
        async loadCompanyFiles() {
            const url = `${baseApiUrl}/companyemployeefilefluig/${this.user.id}`
            await axios.get(url).then(res => {
                this.files = res.data
            })
        },
        async loadUserFiles() {
              if(this.user.id > 0){
                     const url = `${baseApiUrl}/usersfilefluig/${this.user.id}`
                    await axios.get(url).then(res => {
                        this.user_files = res.data
                    })
              }
        },
        async loadUserComplement() {
            if(this.user.id > 0){
                    const url = `${baseApiUrl}/executesearchcomplement/${this.user.id}`
                    await axios.get(url).then(res => {
                        this.user_complement = res.data
                    })
            }

        },
          async loadUserRequest() {
            if(this.user.id > 0){
                    const url = `${baseApiUrl}/executesearchrequest/${this.user.id}`
                    await axios.get(url).then(res => {
                        this.user_request = res.data
                    })
            }

        }
        
    },

    async mounted() {
                await this.loadCompanys()
              //  await this.loadCompanyFiles()
                //await this.loadUserRequest()
                //await this.loadUserFiles()
              //  await this.loadUserComplement()
    }
}
</script>

<style>
.home {
    font-size: 0.7rem;
}

.logo {
    width: 100%;
    background: url(./../../assets/logo.png) no-repeat;
    background-size: 450px;
    padding-top: 550px;
    background-position: center;
}
</style>
