<template>
    <div class="accordion" >
      
           <b-row>
                <b-col md="8" sm="6">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        size="sm"
                    ></b-form-file>
                </b-col>
                  <b-col md="4" sm="6">
                        <b-button
                                title="Salvar"
                                size="sm"
                                variant="dark"
                                @click="[saveFile(),closeFile()]"
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-save"></i>
                            </b-button>
                              <b-button
                                title="Fechar"
                                size="sm"
                                variant="dark"
                                @click="closeFile"
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-times-circle"></i>
                            </b-button>
                  </b-col>
            </b-row>
    </div>       
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'UploadFile',
    props:{company_employee_file:{}},
    data: function() {
        return {
            file: null,
        }
    },
    methods: {
        async saveFile() {
            const method = 'put'
            const id = this.company_employee_file.id
                ? `/${this.company_employee_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployeefiles${id}`,
                this.company_employee_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.file = null
                })
                .catch(showError)
        },
        async saveImagem() {
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('company_id_storage', this.company_employee_file.company_id_employee)
            formData.append('company_employee_id_storage', this.company_employee_file.employee_id)
            formData.append('type_document_id_storage', this.company_employee_file.type_document_id_file)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.getLastStorageFile()
                })
                .catch(showError)
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesbylast/?name_file=${this.file.name}&company_id_storage=${this.company_employee_file.company_id_employee}&company_employee_id_storage=${this.company_employee_file.employee_id}&type_document_id_storage=${this.company_employee_file.type_document_id_file}`
            await axios(url).then(
                res =>
                    (this.company_employee_file = {
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        status_type_document_file: 2,
                        changed:1,
                        id: this.company_employee_file.id,
                        company_employee_id: this.company_employee_file
                            .company_employee_id,
                        type_document_id_file: this.company_employee_file
                            .type_document_id_file
                    })
            )
        },
        closeFile(){
                const showFile = false
                this.$emit('closeFile', showFile)
                this.$emit('loadFile')
        }
    }  

}
</script>

<style>
.accordion{
   font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}


</style>
