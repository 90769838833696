import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted,{
    theme: "bubble",
    position: "top-center",
    iconPack: 'fontawesome',
    fullWidth:false,
    duration: 4500
})

Vue.toasted.register(
    'defaultSuccess',
    payload => !payload.msg ? 'Operação realizada com sucesso!' : payload.msg,
    {type: 'success', icon: 'check'}
)

Vue.toasted.register(
    'defaultWarning',
    payload => !payload.msg ? 'Valide as informações!' : payload.msg,
    {type: 'warning', icon: 'exclamation-triangle'}
)

Vue.toasted.register(
    'defaultInfo',
    payload => !payload.msg ? 'Verifique as informações!' : payload.msg,
    {type: 'info', icon: 'exclamation-triangle'}
)

Vue.toasted.register(
    'defaultError',
    payload => !payload.msg ? 'Ocorreu um erro!' : payload.msg,
    {type: 'error', icon: 'times'}
)