<template>
        <div class="registeruserdoc">
        <PageTitle
            icon="fa fa-clone"
            main="Registro de Documento(s)"
           
        />
        <b-card bg-variant="light" text-variant="dark" title="">
            <b-row>
                <b-col md="12" sm="12">
                    <b-table
                            class="table-responsive"
                        hover
                        head-variant="ligth"
                        :items="user_files"
                        :fields="fieldsFile"
                        >
                        <template #cell(url_type_document_unique)="data">
                            <a @click="openLink(data.value)" :href="`#${data.value.replace(/[^a-z]+/i,'-')}`">{{ data.value }}</a>
                        </template>
                        <template v-slot:cell(documents)="data">
                            <b-button
                                title="Documento Auxiliar"
                                size="sm"
                                variant="outline-secondary"
                                v-if="data.item.model_attach_id_type_document_unique"
                                @click="openLinkDocument(data.item.url_file)"
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-file-word-o"></i>
                                Documento Auxiliar
                            </b-button>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <!--b-button
                                title="Link"
                                size="sm"
                                variant="outline-primary"
                                v-if="data.item.url_type_document"
                                @click="openLink(data.item.url_type_document)"
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-link"></i>
                                Link
                            </b-button!-->
                                <b-button
                                    title="Adicionar Arquivo"
                                    size="sm"
                                    v-if="!data.item.id_documento > 0"
                                    variant="outline-success"
                                    @click="[loadUserFile(data.item),openModalFile()]"
                                    class="mr-2 mt-2"
                                >
                                    <i class="fa fa-plus"></i>
                                    Adicionar
                                </b-button>
                                <b-button
                                    title="Visualizar"
                                    size="sm"
                                    variant="outline-dark"
                                    v-if="data.item.id_documento"
                                    @click="
                                        ;[
                                            loadUserFile(data.item),
                                                openLinkView(data.item.url_file_doc)
                                            // openModalPDF()
                                        ]
                                    "
                                    class="mr-2 mt-2"
                                >
                                    <i class="fa fa-file-pdf-o"></i>
                                    Visualizar
                                </b-button>
                                    <b-button
                                    title="Editar Arquivo"
                                    size="sm"
                                    v-if="data.item.id_documento"
                                    variant="outline-warning"
                                    @click="[loadUserFile(data.item),openModalFileEdit()]"
                                    class="mr-2 mt-2"
                                >
                                    <i class="fa fa-pencil"></i>
                                    Editar
                                </b-button>
                        </template>
                    </b-table>
                </b-col>
                </b-row>
                </b-card>
                <b-modal
                    id="modalPDF"
                    ref="modal"
                    title-tag="h6"
                    centered
                    size="lg"
                    ok-only
                    title="Visualização do Documento"
                >
                    <div>
                        <pdf :src="user_file.url_file_doc"></pdf>
                    </div>
                </b-modal>
        <b-modal
             v-model="modalFileShow"
            id="modalUserFileDoc"
            title-tag="h6"
            centered
            size="lg"
            title="Registro Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
             <b-row  v-show="user_file.has_date">
                  <b-col md="3" sm="12">
                    <b-form-group
                        label="Data de Validade:"
                        label-for="user_file-validade_date"
                        >
                        <b-form-input
                            id="user_file-validade_date"
                            type="date"
                            v-model="user_file.validade_date"
                            size="sm"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-show="user_file.has_date && user_file.validade_date">
                 <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
             <b-row v-show="!user_file.has_date && !user_file.validade_date">
                 <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
        <b-modal
             v-model="modalFileShowEdit"
            id="modalFileEditDoc"
            title-tag="h6"
            centered
            size="lg"
            title="Editar Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
             <b-row  v-show="user_file.has_date">
                  <b-col md="3" sm="12">
                    <b-form-group
                        label="Data Validade:"
                        label-for="user_file-validade_date"
                    >
                        <b-form-input
                            id="user_file-validade_date"
                            type="date"
                            v-model="user_file.validade_date"
                            size="sm"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
             
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShowEdit=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
        </div>
</template>
<script>

import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import pdf from 'vue-pdf'

export default {
    name: 'RegisterUserDoc',
    components: { PageTitle,pdf},
    data: function(){
        return{
                user_files: [],
                users:[],
                user_file:{},
                storage_file: {},
                request_accept:false,
                file_upload_table: false,
                file_doc_show: true,
                file_upload_show: false,
                modalFileShow: false,
                modalFileShowEdit:false,
                file: null,
                fieldsFile: [
                {
                    key: 'name_type_document_unique',
                    label: 'Documento',
                     variant: 'light',
                    sortable: true
                },
                {
                    key: 'description_type_document_unique',
                    label: 'Detalhamento',
                     variant: 'light',
                    sortable: false
                },
                {
                    key: 'url_type_document_unique',
                    label: 'Link',
                     variant: 'light',
                    sortable: false
                }, 
                { key: 'documents', label: '', variant: 'light' },          
                {
                    key: 'name_file_doc',
                    label: 'Arquivo Anexado',
                     variant: 'light',
                    sortable: false
                },
                { key: 'actions', label: '',
                 variant: 'light' ,
                },
                ]
        }
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
     methods: {

            async loadUserFiles() {
                const url = `${baseApiUrl}/typedocumentuniquesuserdocuments/${this.user.id}`
              await  axios(url).then(res => {
                        this.user_files = res.data
                    })
            },
            openModalPDF() {
                this.$bvModal.show('modalPDF')
            },
            openModalFile() {
                this.file_doc_show = false

                this.$bvModal.show('modalUserFileDoc')
            },
            openModalFileEdit(){
                this.file_doc_show = false
                this.$bvModal.show('modalFileEditDoc')
            },
            closeModalFile(){
                this.$bvModal.hide('modalUserFileDoc')
            },
            async saveImagem() {

                    this.file_upload_show = true
                    let formData = new FormData()
                    formData.append('file', this.file)
                    formData.append('user_id_unique', this.user.id)
                    formData.append('type_document_unique_id', this.user_file.id_type_doc)

                    await axios
                        .post(`${baseApiUrl}/storagefiles`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then( async () => {
                            this.$toasted.global.defaultSuccess()
                            await  this.getLastStorageFile()
                    
                        })
                        .finally(async ()=>{
                            if(this.user_file.storage_file_id > 0){
                            await  this.saveFile()
                            }else{
                                this.$toasted.global.defaultWarning({
                                        msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                        });
                            }
                        })
                        .catch(showError)
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesuniquebylast/?name_file=${this.file.name}&user_id_unique=${this.user.id}&type_document_unique_id=${this.user_file.id_type_doc}`
            await axios(url).then(
                res =>
                    (this.user_file = {
                        ...this.user_file,
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        url_ftp: res.data.url_file,
                        user_id_file: this.user.id,
                        type_document_unique_id: this.user_file
                            .id_type_doc
                    })
            )
        },
         async saveFile() {
            
             const method = this.user_file.id_documento ? 'put' : 'post'
            const id = this.user_file.id_documento
                ? `/${this.user_file.id_documento}`
                : ''
            await axios[method](
                `${baseApiUrl}/usersfiles${id}`,
                this.user_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                   // this.closeModalFile()
                })
                .finally(async () => {
                       this.getLastFileTypeDocument()
                     this.file_upload_show = false
                      this.closeModalFile()
                })
                .catch(showError)
        },
          async getLastFileTypeDocument() {
            const url = `${baseApiUrl}/usersfilesbylast/?user_id_file=${this.user.id}&type_document_id_unique=${this.user_file.id_type_doc}`
            await axios(url).then(
                res =>
                    (
                        this.checkFileFtpById(res.data)
                    )
            )
        },
         async checkFileFtpById(item) {

             if(item.storage_file_id > 0){

            await axios['put'](
                `${baseApiUrl}/useresfilesftpbyid/${item.id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.modalFileShow = false
                     this.modalFileShowEdit = false
                })
                .finally( () => {
                        this.loadUserFiles()
                           
                })
                .catch(showError)
             }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }    
        },
         openLink(item){
            const url = item.replace(" ","")
            window.open(url)
        },      
        openLinkView(item){
            const url = item
            window.open(url)
        },
        async openLinkDocument(item){
            const url = item
            await axios.get(url)
            window.open(url)
        },
        openModalUserFile() {
            this.$bvModal.show('modalUserFile')
        },
        loadUserFile(item) {
            this.user_file = { ...item }
        },

     },
    async mounted() {
        await this.loadUserFiles()
    }
}
</script>

<style>
.registeruserdoc {
    font-size: 0.75rem;
}

.registeruserdoc button {
    font-size: 0.75rem;
}

#modalUserFileDoc {
     font-size: 0.75rem;
}

#modalUserFileDoc button{
     font-size: 0.75rem;
}

#modalUserFileDoc input{
     font-size: 0.75rem;
}


#modalFileEditDoc {
     font-size: 0.75rem;
}

#modalFileEditDoc button{
     font-size: 0.75rem;
}

#modalFileEditDoc input{
     font-size: 0.75rem;
}

</style>