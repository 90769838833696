<template>
        <div class="registermonthlydoc">
        <PageTitle
            icon="fa fa-clone"
            main="Registro de Documento(s)"
            :sub = company_monthly.description_monthly
           
        />
            <b-card bg-variant="light" text-variant="dark" title="">
                <b-row>
                    <b-col md="12" sm="12">
                        <b-table
                            hover
                            head-variant="ligth"
                            :items="company_monthly_files"
                            :fields="fieldsFile"
                            >
                            <template #cell(url_type_document_monthly)="data">
                                <a @click="openLink(data.value)" :href="`#${data.value.replace(/[^a-z]+/i,'-')}`">{{ data.value }}</a>
                            </template>
                             <template v-slot:cell(documents)="data">
                                <b-button
                                    title="Documento Auxiliar"
                                    size="sm"
                                    variant="outline-secondary"
                                    v-if="data.item.model_attach_id_type_document_monthly"
                                    @click="openLinkDocument(data.item.url_file)"
                                    class="mr-2 mt-2"
                                >
                                    <i class="fa fa-file-word-o"></i>
                                    Documento Auxiliar
                                </b-button>
                             </template>
                            <template v-slot:cell(actions)="data">
                                <!--b-button
                                    title="Link"
                                    size="sm"
                                    variant="outline-primary"
                                    v-if="data.item.url_type_document_monthly"
                                    @click="openLink(data.item.url_type_document_monthly)"
                                    class="mr-2 mt-2"
                                >
                                    <i class="fa fa-link"></i>
                                    Link
                                </b-button!-->
                                    <b-button
                                        title="Adicionar Arquivo"
                                        size="sm"
                                        v-if="!data.item.id_documento > 0"
                                        variant="outline-success"
                                        @click="[loadMonthlyFile(data.item),openModalFile()]"
                                        class="mr-2 mt-2"
                                    >
                                        <i class="fa fa-plus"></i>
                                        Adicionar
                                    </b-button>
                                    <b-button
                                        title="Visualizar"
                                        size="sm"
                                        variant="outline-dark"
                                        v-if="data.item.id_documento"
                                        @click="
                                            ;[
                                                loadMonthlyFile(data.item),
                                                openLinkView(data.item.url_file_doc)
                                                //openModalPDF()
                                            ]
                                        "
                                        class="mr-2 mt-2"
                                    >
                                        <i class="fa fa-file-pdf-o"></i>
                                        Visualizar
                                    </b-button>
                                        <b-button
                                        title="Editar Arquivo"
                                        size="sm"
                                        v-if="data.item.id_documento"
                                        variant="outline-warning"
                                        @click="[loadMonthlyFile(data.item),openModalFileEdit()]"
                                        class="mr-2 mt-2"
                                    >
                                    <i class="fa fa-pencil"></i>
                                        Editar
                                    </b-button>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                </b-card>
                <b-modal
                    id="modalPDF"
                    ref="modal"
                    title-tag="h6"
                    centered
                    size="lg"
                    ok-only
                    title="Visualização do Documento"
                >
                    <div>
                        <pdf :src="company_monthly_file.url_file_doc"></pdf>
                    </div>
                </b-modal>
         <b-modal
             v-model="modalFileShow"
            id="modalMonthlyFileDoc"
            title-tag="h6"
            centered
            size="lg"
            title="Registro Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
            <b-row>
                 <b-col md="5" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShow=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
        <b-modal
             v-model="modalFileShowEdit"
            id="modalFileEditDoc"
            title-tag="h6"
            centered
            size="lg"
            title="Editar Documento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
    
        >
            <b-row>
                <b-col md="12" sm="12">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        :disabled="file_doc_show"
                        size="sm"
                    ></b-form-file>
                </b-col>
            </b-row>
            <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        v-show="!file_upload_show"
                        class="float-right"
                        @click="modalFileShowEdit=false"
                    >
                        Fechar
                    </b-button>
                    <b-button variant="primary" disabled  v-show="file_upload_show">
                        <b-spinner small type="grow"></b-spinner>
                        Carregando...
                    </b-button>
                    </div>
                </template>
        </b-modal>
        </div>
</template>
<script>

import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import PageTitle from '../template/PageTitle'
import pdf from 'vue-pdf'

export default {
    name: 'RegisterMonthlyDoc',
    props: ['company_monthly'],
    components: { PageTitle,pdf},
    data: function(){
        return{
                company_monthly_files: [],
                company_monthly_file:{},
                storage_file: {},
                request_accept:false,
                file_upload_table: false,
                file_doc_show: true,
                file_upload_show: false,
                modalFileShow: false,
                modalFileShowEdit:false,
                file: null,
                fieldsFile: [
                {
                    key: 'name_type_document_monthly',
                    label: 'Documento',
                     variant: 'light',
                     thStyle: { width: "15%" },
                    sortable: true
                },
                {
                    key: 'description_type_document_monthly',
                    label: 'Detalhamento',
                    thStyle: { width: "40%" },
                     variant: 'light',
                    sortable: false
                },   
                {
                    key: 'url_type_document_monthly',
                    label: 'Link',
                     variant: 'light',
                     thStyle: { width: "15%" },
                    sortable: false
                },    
                { key: 'documents', label: '', variant: 'light',thStyle: { width: "10%" }, },
                {
                    key: 'name_file_doc',
                    label: 'Arquivo Anexado',
                     variant: 'secondary',
                     thStyle: { width: "10%" },
                    sortable: false
                },
                { key: 'actions', label: '', variant: 'light' , thStyle: { width: "10%" },},
                ]
        }
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        user() {
            return this.$store.state.user
        }
    },
     methods: {

            async loadMonthlyFiles() {
                const url = `${baseApiUrl}/typedocumentmonthlydocuments/${this.company_monthly.id}`
              await  axios(url).then(res => {
                        this.company_monthly_files = res.data
                    })
            },
            openModalPDF() {
                this.$bvModal.show('modalPDF')
            },
            openModalFile() {
                this.file_doc_show = false

                this.$bvModal.show('modalMonthlyFileDoc')
            },
            closeModalFile(){
                this.$bvModal.hide('modalMonthlyFileDoc')
            },
            async saveImagem() {

                    this.file_upload_show = true
                    let formData = new FormData()
                    formData.append('file', this.file)
                    formData.append('company_doc_monthly_id_storage', this.company_monthly.id)
                    formData.append('type_document_monthly_id_storage', this.company_monthly_file.id_type_doc)

                    await axios
                        .post(`${baseApiUrl}/storagefiles`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then( async () => {
                            this.$toasted.global.defaultSuccess()
                            await  this.getLastStorageFile()
                        
                        })
                        .finally(async ()=>{
                            if(this.company_monthly_file.storage_file_id > 0){
                            await  this.saveFile()
                            }else{
                                this.$toasted.global.defaultWarning({
                                        msg: "Nâo foi possivel gravar o arquivo!Tente novamente....",
                                        });
                            }
                        })
                        .catch(showError)
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesmonthlydbylast/?company_doc_monthly_id_storage=${this.company_monthly.id}&type_document_monthly_id_storage=${this.company_monthly_file.id_type_doc}`
            await axios(url).then(
                res =>
                    (this.company_monthly_file = {
                        ...this.company_monthly_file,
                        storage_file_id: res.data.id,
                        name_file: res.data.name_file,
                        path_file: res.data.path_file,
                        url_ftp: res.data.url_file,
                        company_doc_monthly_id: this.company_monthly.id,
                        type_documento_monthly_id: this.company_monthly_file
                            .id_type_doc
                    })
            )
        },
         async saveFile() {

            const method = this.company_monthly_file.id_documento ? 'put' : 'post'
            const id = this.company_monthly_file.id_documento
                ? `/${this.company_monthly_file.id_documento}`
                : ''
            await axios[method](
                `${baseApiUrl}/companydocumentmonthlyfiles${id}`,
                this.company_monthly_file
            )
                .then( ()=> {
                    this.$toasted.global.defaultSuccess()  
                })
                .finally(async () => {
                     this.getLastFileTypeDocument()
                     this.file_upload_show = false
                      this.closeModalFile()
                })
                .catch(showError)
        },
          async getLastFileTypeDocument() {
            const url = `${baseApiUrl}/companydocumentmonthlyfilesbylast/?company_doc_monthly_id=${this.company_monthly.id}&type_documento_monthly_id=${this.company_monthly_file.type_documento_monthly_id}`
            await axios(url).then(
                res =>
                    (
                        this.checkFileFtpById(res.data)
                    )
            )
        },
         async checkFileFtpById(item) {

             if(item.storage_file_id > 0){

            await axios['put'](
                `${baseApiUrl}/companydocumentmonthlyfilesftp/${item.id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.modalFileShow = false
                     this.modalFileShowEdit = false
                })
                .finally( () => {
                        this.loadMonthlyFiles()
                           
                })
                .catch(showError)
             }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }    
          
          
        },
         openLink(item){
            const url = item.replace(" ","")
            //window.location.href=url
            window.open(url,'_blank')
        },
        openLinkView(item){
            const url = item
            window.open(url)
        },
        async openLinkDocument(item){
            const url = item
            await axios.get(url)
            window.open(url)
        },
        openModalMonthlyFile() {
            this.$bvModal.show('modalMonthlyFile')
        },
        openModalFileEdit(){
            this.file_doc_show = false
            this.$bvModal.show('modalFileEditDoc')
        },
        loadMonthlyFile(item) {
            this.company_monthly_file = { ...item }
        },

     },
    async mounted() {
        await this.loadMonthlyFiles()
    }
}
</script>

<style>
.registermonthlydoc {
    font-size: 0.75rem;
}

.registermonthlydoc button {
    font-size: 0.75rem;
}

#modalFile {
     font-size: 0.75rem;
}

#modalFile button{
     font-size: 0.75rem;
}

#modalFileEditDoc {
     font-size: 0.75rem;
}

#modalFileEditDoc button {
     font-size: 0.75rem;
}

</style>