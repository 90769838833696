<template>
    <div class="accordion" >
           <b-row>
                <b-col md="8" sm="6">
                    <b-form-file
                        accept=".pdf"
                        v-model="file"
                        class="mt-4 mb-4"
                        plain
                        @input="saveImagem"
                        size="sm"
                    ></b-form-file>
                </b-col>
                  <b-col md="4" sm="6">
                        <b-button
                                title="Salvar"
                                size="sm"
                                variant="dark"
                                @click="saveFile()"
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-save"></i>
                            </b-button>
                              <b-button
                                title="Fechar"
                                size="sm"
                                variant="dark"
                                @click="closeFile"
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-times-circle"></i>
                            </b-button>
                  </b-col>
            </b-row>
    </div>       
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'UploadFileMonthly',
    props:{company_monthly_file:{}},
    data: function() {
        return {
            file: null,
        }
    },
    methods: {
        async saveFile() {
            const method = 'put'
            const id = this.company_monthly_file.id
                ? `/${this.company_monthly_file.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companydocumentmonthlyfiles${id}`,
                this.company_monthly_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    
                })
                .finally(() => {
                    this.closeFile()
                    
                })
                .catch(showError)
        },
        async saveImagem() {
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('company_doc_monthly_id_storage', this.company_monthly_file.company_doc_monthly_id)
            formData.append('type_document_monthly_id_storage', this.company_monthly_file.type_documento_monthly_id)

            await axios
                .post(`${baseApiUrl}/storagefiles`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.getLastStorageFile()
                })
                .catch(showError)
        },
        async getLastStorageFile() {
            const url = `${baseApiUrl}/storagefilesmonthlydbylast/?company_doc_monthly_id_storage=${this.company_monthly_file.company_doc_monthly_id}&type_document_monthly_id_storage=${this.company_monthly_file.type_documento_monthly_id}`
            await axios(url).then(
                res =>
                    (this.company_monthly_file = {
                            company_doc_monthly_id: this.company_monthly_file.company_doc_monthly_id,
                            storage_file_id: res.data.id,
                            name_file: res.data.name_file,
                            path_file: res.data.path_file,
                            id: this.company_monthly_file.id,
                            changed:1,
                            status_type_document_file: 2,
                            type_documento_monthly_id: this.company_monthly_file
                            .type_documento_monthly_id
                    })
            )
        },
        closeFile(){
                const showFile = false
                this.$emit('closeFile', showFile)
                this.$emit('loadFile')
        }
    }  

}
</script>

<style>
.accordion{
   font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}


</style>
