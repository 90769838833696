<template>
    <div class="accordion" >
                <b-row>
                 <b-button
                        title="Atualizar Lista"
                        size="sm"
                        variant="light"
                        @click="loadCompanyEmployeeFiles()"
                        class="ml-4 mr-2 mt-2 mb-2"
                    >
                        <i class="fa fa-refresh"></i>
                        Atualizar
                </b-button>
                <b-button
                                title="Envio"
                                size="sm"
                                variant="light"
                               @click="changeFile()"
                                class=" mr-2 mt-2 mb-2"
                            >
                                <i class="fa fa-exchange"></i>
                                Solicitar Autorização
                        </b-button>
        </b-row>
            <b-table
                class="table-responsive"
                hover
                head-variant="ligth"
                :items="company_employee_files"
                :fields="fields"
            >
                <template v-slot:cell(actions)="data">
                    <b-button
                        title="Visualizar"
                        size="sm"
                        variant="light"
                        v-show="!showPreview"
                        @click="
                            ;[
                                loadCompanyEmployeeFile(data.item),
                                showEmployeeFilePDF()
                            ]
                        "
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-file-pdf-o"></i>
                        Visualizar
                    </b-button>
                    <b-button
                        title="Fechar Visualização"
                        size="sm"
                        v-show="showPreview"
                        variant="light"
                        @click="
                            ;[
                                hiddenEmployeeFilePDF()
                            ]
                        "
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-times-circle"></i>
                        Fechar Visualização
                    </b-button>
                    <b-button
                        title="Editar registro"
                        size="sm"
                           v-if="data.item.storage_file_id > 0 && !data.item.changed"
                        variant="light"
                        @click="
                            ;[
                                loadCompanyEmployeeFile(data.item),
                                showEmployeeFile()
                            ]
                        "
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-pencil"></i>
                        Editar
                    </b-button>
                     <b-button
                                title="Confirmar Envio"
                                size="sm"
                                v-if="data.item.storage_file_id > 0 && data.item.changed && !data.item.return_document_change_id > 0"
                                variant="light"
                                @click="checkFileFtp(data.item)"
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-check"></i>
                                Confirmar Envio
                        </b-button>
                          <!--b-button
                                title="Envio"
                                size="sm"
                                v-if="data.item.return_document_change_id > 0"
                                variant="light"
                                @click="changeFile(data.item)"
                                class="mr-2 mt-2"
                            >
                                <i class="fa fa-envelope"></i>
                                Enviar
                        </b-button!-->
                </template>
            </b-table>
             <PreviewFile v-show="showPreview" :company_employee_file = "company_employee_file" />
             <keep-alive>
             <UploadFile v-show="showFile" 
                                :company_employee_file = "company_employee_file" 
                                 @closeFile="showFile =  $event.showFile"
                                 @loadFile=" loadCompanyEmployeeFiles()" 
                                   
                                   />
             </keep-alive>                     

    </div>       
</template>

<script>
import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import PreviewFile from './../util/PreviewFile'  
import UploadFile from './../util/UploadFile'  

export default {
    name: 'ListEmployeeFile',
     components: { PreviewFile,UploadFile },
    props:["employee_id"],
    data: function() {
        return {
            company_employee_file: {},
            company_employee_files: [],
            showPreview: false,
            showFile: false,
            file_id:null,
            fields: [
                {
                    key: 'name_type_document',
                    label: 'Documento',
                     variant: 'light',
                    sortable: true
                },
                {
                    key: 'name_file',
                    label: 'Arquivo',
                      variant: 'light',
                    sortable: false
                },
                {
                    key: 'justify_comments',
                    label: 'Justificativa',
                     variant: 'light',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'light' }
            ]
        
        }
    },
    methods: {
        loadCompanyEmployeeFiles() {
            const url = `${baseApiUrl}/companyemployeefilesusers/${this.employee_id}`
           axios(url).then(res => {
                this.company_employee_files = res.data
            })
        },
          async checkFileFtp(item) {
            this.company_employee_file = { ...item }
            
            if(item.storage_file_id > 0){
                  await axios['put'](
                `${baseApiUrl}/companyemployeefilesftp/${this.company_employee_file.id}`,
                this.company_employee_file
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                     this.loadCompanyEmployeeFiles()
                })
                .catch(showError)
            }else{
                this.$toasted.global.defaultWarning({
                    msg: "Não existe arquivo anexo para envio!Favor verificar..",
                    });
            }
          
        },
        loadCompanyEmployeeFile(item) {
            this.company_employee_file = { ...item }
        },
        showEmployeeFilePDF(){
            this.showPreview = true
        },
        hiddenEmployeeFilePDF(){
              this.showPreview = false
        },
        showEmployeeFile(){
            this.showFile = true
        },
         async changeFile() {           

                  await axios['post'](
                `${baseApiUrl}/companyemployeefileschange/${this.employee_id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()                
                })
                .catch(showError)
        }
    }  
    ,
    async  mounted() {
     await   this.loadCompanyEmployeeFiles()
    }
}
</script>

<style>
.accordion{
   font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}


@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

</style>
