<template>
    <footer class="footer">
        <span>Version 2.3 Copyright <strong> PORTALVOCE </strong> ® 2024 </span>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>
.footer {
    grid-area: footer;
    background: linear-gradient(to right, #f37617, #f4791f);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    font-size: 0.7rem;
}
</style>
