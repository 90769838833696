<template>
    <header class="header">
        <a class="toggle" @click="toggleMenu" v-if="!hideToggle">
            <i class="fa fa-lg" :class="icon"></i>
        </a>
        <h1 class="title">
            <router-link to="/">Portal do Parceiro</router-link>
        </h1>

        <UserDropdown v-if="!hideUserDropdown" />
    </header>
</template>

<script>
import UserDropdown from './UserDropdown'

export default {
    name: 'Header',
    components: { UserDropdown },
    props: {
        title: String,
        hideToggle: Boolean,
        hideUserDropdown: Boolean
    },
    computed: {
        compan() {
            return this.$store.state.compan
        },
        icon() {
            return this.$store.state.isMenuVisible
                ? 'fa-reorder'
                : 'fa-angle-down'
        }
    },
    methods: {
        toggleMenu() {
            this.$store.commit('toggleMenu')
        }
    }
}
</script>

<style>
.header {
    grid-area: header;
    background: linear-gradient(to left, #4f0281, #03001e);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
}
.title {
    font-size: 1rem;
    color: #fff;
    font-weight: 50;
    padding-top: 10px;
    flex-grow: 1;
    text-align: center;
}
.title a {
    color: #fff;
    text-decoration: none;
}

.Slide {
    color: #fff;
    text-decoration: none;
    align-items: top;
}

.title a:hover {
    color: #fff;
    text-decoration: none;
}

header.header > a.toggle {
    width: 50px;
    height: 100%;
    color: #fff;
    justify-self: flex-start;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

header.header > a.toggle:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
}
</style>
