var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accordion"},[_c('b-row',[_c('b-button',{staticClass:"ml-4 mr-2 mt-2 mb-2",attrs:{"title":"Atualizar Lista","size":"sm","variant":"light"},on:{"click":function($event){return _vm.loadCompanyEmployeeFiles()}}},[_c('i',{staticClass:"fa fa-refresh"}),_vm._v(" Atualizar ")]),_c('b-button',{staticClass:" mr-2 mt-2 mb-2",attrs:{"title":"Envio","size":"sm","variant":"light"},on:{"click":function($event){return _vm.changeFile()}}},[_c('i',{staticClass:"fa fa-exchange"}),_vm._v(" Solicitar Autorização ")])],1),_c('b-table',{staticClass:"table-responsive",attrs:{"hover":"","head-variant":"ligth","items":_vm.company_employee_files,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPreview),expression:"!showPreview"}],staticClass:"mr-2 mt-2",attrs:{"title":"Visualizar","size":"sm","variant":"light"},on:{"click":function($event){;[
                            _vm.loadCompanyEmployeeFile(data.item),
                            _vm.showEmployeeFilePDF()
                        ]}}},[_c('i',{staticClass:"fa fa-file-pdf-o"}),_vm._v(" Visualizar ")]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview),expression:"showPreview"}],staticClass:"mr-2 mt-2",attrs:{"title":"Fechar Visualização","size":"sm","variant":"light"},on:{"click":function($event){;[
                            _vm.hiddenEmployeeFilePDF()
                        ]}}},[_c('i',{staticClass:"fa fa-times-circle"}),_vm._v(" Fechar Visualização ")]),(data.item.storage_file_id > 0 && !data.item.changed)?_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"title":"Editar registro","size":"sm","variant":"light"},on:{"click":function($event){;[
                            _vm.loadCompanyEmployeeFile(data.item),
                            _vm.showEmployeeFile()
                        ]}}},[_c('i',{staticClass:"fa fa-pencil"}),_vm._v(" Editar ")]):_vm._e(),(data.item.storage_file_id > 0 && data.item.changed && !data.item.return_document_change_id > 0)?_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"title":"Confirmar Envio","size":"sm","variant":"light"},on:{"click":function($event){return _vm.checkFileFtp(data.item)}}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Confirmar Envio ")]):_vm._e()]}}])}),_c('PreviewFile',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreview),expression:"showPreview"}],attrs:{"company_employee_file":_vm.company_employee_file}}),_c('keep-alive',[_c('UploadFile',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFile),expression:"showFile"}],attrs:{"company_employee_file":_vm.company_employee_file},on:{"closeFile":function($event){_vm.showFile =  $event.showFile},"loadFile":function($event){return _vm.loadCompanyEmployeeFiles()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }