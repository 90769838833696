var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rejectfilemonth"},[_c('PageTitle',{attrs:{"icon":"fa fa-clone","main":"Documento Rejeitado","sub":"Mensal"}}),_c('b-card',{attrs:{"bg-variant":"light","text-variant":"dark","title":"Contrato(s)"}},[_c('b-card-text',[_vm._v(" Selecione o contrato para apresentar o(s) período(s). ")]),_c('b-row',[_c('b-col',{attrs:{"md":"3","sm":"6"}},[_c('b-table',{ref:"selectableTable",staticClass:"table-responsive",attrs:{"items":_vm.companys,"fields":_vm.fieldsCompany,"select-mode":_vm.selectMode,"selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selecionado")])]:[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Não Selecionado")])]]}}])})],1),_c('b-col',{attrs:{"md":"3","sm":"6"}},[_c('b-table',{staticClass:"table-responsive",attrs:{"select-mode":_vm.selectMode,"selectable":"","items":_vm.company_monthlys,"fields":_vm.fieldsMonth},on:{"row-selected":_vm.onRowMonthSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selecionado")])]:[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Não Selecionado")])]]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:" mr-2",attrs:{"title":"Solicitar Autorização","size":"sm","variant":"info","disabled":_vm.request_accept},on:{"click":function($event){return _vm.changeFile(data.item)}}},[_c('div',{staticClass:"d-flex justify-content-center "},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.request_accept),expression:"request_accept"}],attrs:{"label":"Enviando..."}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.request_accept),expression:"!request_accept"}]},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.request_accept),expression:"!request_accept"}],staticClass:"fa fa-exchange"}),_vm._v(" Solicitar Autorização ")])])]}}])})],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-table',{staticClass:"table-responsive",attrs:{"hover":"","head-variant":"ligth","items":_vm.company_monthly_files,"fields":_vm.fieldsFile},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"title":"Visualizar","size":"sm","variant":"outline-dark"},on:{"click":function($event){;[
                                                _vm.loadFile(data.item),
                                                _vm.openModalPDF()
                                            ]}}},[_c('i',{staticClass:"fa fa-file-pdf-o"}),_vm._v(" Visualizar ")]),_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"title":"Editar Arquivo","size":"sm","variant":"outline-dark"},on:{"click":function($event){[_vm.loadFile(data.item),_vm.openModalFile()]}}},[_c('i',{staticClass:"fa fa-pencil"}),_vm._v(" Editar ")])]}}])})],1)],1)],1),_c('b-modal',{ref:"modal",attrs:{"id":"modalPDF","title-tag":"h6","centered":"","size":"lg","ok-only":"","title":"Visualização do Documento"}},[_c('div',[_c('pdf',{attrs:{"src":_vm.company_monthly_file.url_file}})],1)]),_c('b-modal',{attrs:{"id":"modalFile","title-tag":"h6","centered":"","size":"lg","title":"Arquivo"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.file_upload_show),expression:"!file_upload_show"}],staticClass:"float-right",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.modalFileShow=false}}},[_vm._v(" Fechar ")]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.file_upload_show),expression:"file_upload_show"}],attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Carregando... ")],1)],1)]},proxy:true}]),model:{value:(_vm.modalFileShow),callback:function ($$v) {_vm.modalFileShow=$$v},expression:"modalFileShow"}},[_c('b-row',[_c('b-col',{attrs:{"md":"5","sm":"12"}},[_c('b-form-file',{staticClass:"mt-4 mb-4",attrs:{"accept":".pdf","plain":"","disabled":_vm.file_doc_show,"size":"sm"},on:{"input":_vm.saveImagem},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }