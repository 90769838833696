<template>
    <div id="app" :class="{ 'hide-menu': !isMenuVisible || !user }">
        <Header title="" :hideToggle="!user" :hideUserDropdonw="!user" />
        <Menu v-if="user" />
        <Loading v-if="validatingToken" />
        <Content v-else />
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, userKey } from '@/global'
import { mapState } from 'vuex'

import Header from '@/components/template/Header'
import Content from '@/components/template/Content'
import Menu from '@/components/template/Menu'

import Footer from '@/components/template/Footer'
import Loading from '@/components/template/Loading'

export default {
    name: 'App',
    components: { Header, Content, Menu, Footer, Loading },
    computed: mapState(['isMenuVisible', 'user']),
    data: function() {
        return {
            validatingToken: true
        }
    },
    methods: {
        async validateToken() {
            this.validatingToken = true

            const json = localStorage.getItem(userKey)
            const userData = JSON.parse(json)
            this.$store.commit('setUser', null)

            if (!userData) {
                this.validatingToken = false
                return this.$router.push({ name: 'auth' })
            }

            const res = await axios.post(
                `${baseApiUrl}/validateToken`,
                userData
            )
            if (res.data) {
                this.$store.commit('setUser', userData)
                this.$store.commit('toggleMenu', false)
            } else {
                localStorage.removeItem(userKey)
                this.$router.push({ name: 'auth' })
            }

            this.validatingToken = false
        }
    },
    created() {
        this.validateToken()
    }
}
</script>

<style>
* {
    font-family: Arial, Helvetica, sans-serif;
}
body {
    margin: 0;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    height: 100vh;
    display: grid;
    grid-template-rows: 35px 1fr 35px;
    grid-template-columns: 170px 1fr;
    grid-template-areas:
        'header header'
        'menu content'
        'footer footer';
}
#app.hide-menu {
    grid-template-columns: 30px 1fr;
    grid-template-areas:
        'header header'
        'menu content'
        'footer footer';
}
</style>
