<template>
    <div class="accordion" >
        <pdf :src="company_monthly_file.url_file"></pdf>
    </div>       
</template>

<script>
import pdf from 'vue-pdf'

export default {
    name: 'PreviewFileMonthly',
     components: {  pdf },
    props:["company_monthly_file"],
    data: function() {
        return {
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
        }
    },
    methods: {

    } ,
    
}
</script>

<style>
.accordion{
   font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}



</style>
