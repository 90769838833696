<template>
    <div class="accordion" >
        <pdf :src="company_employee_file.url_file"></pdf>
    </div>       
</template>

<script>
import pdf from 'vue-pdf'

export default {
    name: 'PreviewFile',
     components: {  pdf },
    props:["company_employee_file"],
    data: function() {
        return {
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
        }
    },
    methods: {
        openModalEmployeeFilePDF() {
            this.$bvModal.show('modalEmployeeFilePDF')
        },
    } ,
    
}
</script>

<style>
.accordion{
   font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}



</style>
