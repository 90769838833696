<template>
    <div class="user-admin">
        <b-form
            v-show="mode === 'save' || mode === 'remove' || mode === 'read'"
        >
            <input id="user-id" type="hidden" v-model="user.id" />
            <b-row>
                <b-col md="8" sm="12">
                    <b-form-group label="E-mail:" label-for="user-email">
                        <b-form-input
                            id="user-email"
                            type="text"
                            v-model="user.email"
                            required
                            size="sm"
                            :readonly="mode === 'remove' || mode === 'read'"
                            placeholder="E-mail..."
                        />
                    </b-form-group>
                </b-col>
                 <b-col md="4" sm="12">
                    <b-form-group label="CNPJ:" label-for="user-register_number">
                        <b-form-input
                            id="user-register_number"
                            type="text"
                            v-model="user.register_number"
                            required
                            size="sm"
                            :readonly="true"
                            placeholder="CNPJ..."
                        />
                    </b-form-group>
                </b-col>
            </b-row>
           
        </b-form>
        <b-row>
            <b-col xs="12">
                <b-button
                    title="Salvar"
                    size="sm"
                    variant="light"
                    class="mt-2 mb-2 ml-2 fa fa-save"
                    v-if="mode === 'save'"
                    @click="save"
                ></b-button>

                <b-button
                    title="Voltar"
                    size="sm"
                    variant="light"
                    class="mt-2 mb-2 ml-2 fa fa-ban"
                    v-if="
                        mode === 'remove' || mode === 'save' || mode === 'read'
                    "
                    @click="reset"
                >
                </b-button>
                <b-button
                    size="sm"
                    title="Pesquisar"
                    class="mt-2 mb-2 ml-2 fa fa-search"
                    @click=";[loadUsers()]"
                    variant="dark"
                    v-show="mode !== 'save' && mode !== 'remove'"
                >
                </b-button>
            </b-col>
        </b-row>
        <b-row v-show="mode !== 'save' && mode !== 'remove'">
            <div class="col-sm-6">
                <b-form-group>
                    <input
                        size="lg"
                        type="text"
                        icon="search"
                        v-model="search"
                        placeholder="Informe o e-mail"
                        class="form-control"
                    />
                </b-form-group>
            </div>
            <b-table
                class="table-responsive"
                hover
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredList"
                :fields="fields"
            >
                <template v-slot:cell(actions)="data">
                    <b-button
                        size="sm"
                        variant="light"
                        title="Editar"
                        @click=";[loadUser(data.item), alterModo()]"
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-pencil"></i>
                    </b-button>
                     <b-button
                        size="sm"
                        title="Remover"
                        variant="light"
                        @click="loadUser(data.item)"
                        class="mr-2 mt-2"
                    >
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
             <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="rows"
            >
            </b-pagination>
        </b-row>
        <b-modal
            id="modalPassword"
            ref="modal"
            title-tag="h6"
            title="Alterar Senha"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            @ok="passwordChange"
        >
            <b-row v-show="mode === 'save'">
                <b-col md="6" sm="12">
                    <b-form-group label="Senha:" label-for="user-password">
                        <b-form-input
                            id="user-password"
                            type="password"
                            size="sm"
                            v-model="user.password"
                            placeholder="Senha do Usuário..."
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                    <b-form-group
                        label="Confirmação de Senha:"
                        label-for="user-confirm-password"
                    >
                        <b-form-input
                            id="user-confirm-password"
                            type="password"
                            size="sm"
                            v-model="user.confirmPassword"
                            placeholder="Confirme a Senha..."
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal
            id="modalBlocked"
            ref="modal"
            title-tag="h6"
            title="Bloquear Usuário"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            @ok="blocked"
        >
            <p class="my-4">
                Este Procedimento irá retirar o acesso do usuário.
                <b-badge>{{ user.name }}</b-badge>
            </p>
        </b-modal>
        <b-modal
            id="modalUnBlocked"
            ref="modal"
            title-tag="h6"
            title="Desbloquear Usuário"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            @ok="unblocked"
        >
            <p class="my-4">
                Este Procedimento irá permitir o acesso do usuário.
                <b-badge>{{ user.name }}</b-badge>
            </p>
        </b-modal>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'UserAdmin',
    data: function() {
        return {
            mode: 'insert',
            search: '',
            user: {},
            users: [],
            companys: [],
            setores: [],
            currentPage: 1,
            perPage: 5,
            headerBgVariant: 'primary',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
            fields: [
                { key: 'email', label: 'E-mail', sortable: true },
                { key: 'register_number', label: 'CPNJ', sortable: true },
                { key: 'actions', label: 'Ações', variant: 'secondary' }
            ],
            options: [
                { value: 1, text: 'sim' },
                { value: 0, text: 'Não' }
            ]
        }
    },
    computed: {
        filteredList() {
            return this.users.filter(user => {
                return user.email
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
            })
        },
        rows() {
            return this.users.length
        }
    },
    methods: {
        loadUsers() {
            const url = `${baseApiUrl}/users`
            axios.get(url).then(res => {
                this.users = res.data
            })
        },
        reset() {
            this.user = {}
            this.mode = 'insert'
            this.loadUsers()
        },
        alterModo() {
            this.mode = 'save'
        },
        save() {
            const method = this.user.id ? 'put' : 'post'
            const id = this.user.id ? `/${this.user.id}` : ''
            axios[method](`${baseApiUrl}/users${id}`, this.user)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        passwordChange() {
            const method = this.user.id ? 'put' : 'post'
            const id = this.user.id ? `/${this.user.id}` : ''
            axios[method](`${baseApiUrl}/passwordChange${id}`, this.user)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        remove() {
            const id = this.user.id
            axios
                .delete(`${baseApiUrl}/users/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        blocked() {
            const id = this.user.id
            axios
                .put(`${baseApiUrl}/blocked/${id}`, this.user)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        unblocked() {
            const id = this.user.id
            axios
                .put(`${baseApiUrl}/unblocked/${id}`, this.user)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadUser(user, mode = 'save') {
            this.mode = mode
            this.user = { ...user }
            this.$refs.searchFocus.focus()
        }
    },
    mounted() {
        this.loadUsers()
    }
}
</script>

<style>
.user-admin select {
    font-size: 0.75rem;
}
.user-admin input {
    font-size: 0.75rem;
}
.user-admin button {
    font-size: 0.75rem;
}
.user-admin table {
    font-size: 0.75rem;
}

#modalPassword {
    font-size: 0.8rem;
}

#modalPassword label {
    font-size: 0.8rem;
}

#modalPassword button {
    font-size: 0.8rem;
}

#modalPassword input {
    font-size: 0.8rem;
}

#modalBlocked {
    font-size: 0.75rem;
}

#modalBlocked label {
    font-size: 0.75rem;
}

#modalBlocked button {
    font-size: 0.75rem;
}

#modalUnBlocked {
    font-size: 0.75rem;
}

#modalUnBlocked label {
    font-size: 0.75rem;
}

#modalUnBlocked button {
    font-size: 0.75rem;
}
</style>
