<template>
    <div class="company-admin">
        <b-row>
             <b-col md="6" sm="12">
            <b-form-group
                    label="Parceiro:"
                    label-for="company-user_id"
                >
                    <b-form-select
                        id="company-user_id"
                        size="sm"
                        :options="users"
                        @input="loadCompanys"
                        v-model=" user_id"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-show="mode !== 'save' && mode !== 'remove'">
            <div class="col-sm-6">
                <b-form-group>
                    <input
                        size="lg"
                        type="text"
                        icon="search"
                        v-model="search"
                        placeholder="Informe o nome do empreendimento"
                        class="form-control"
                    />
                </b-form-group>
            </div>
            <transition name="slide" type="animation" appear>
                <b-table
                    :busy="isBusy"
                    class="table-responsive"
                    hover
                    key="slide"
                    head-variant="light"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="filteredList"
                    :fields="fields"
                >
                    <template v-slot:table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Carregando...</strong>
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            title="Editar Empreendimento"
                            size="sm"
                            variant="light"
                            @click="openModalCompany(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-pencil"></i>
                        </b-button>
                            <b-button
                            title="Funcionário(s)"
                            size="sm"
                            variant="light"
                            @click="openModalCompanyEmployee(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-address-book-o"></i>
                        </b-button>
                        <b-button
                            title="Mensal"
                            size="sm"
                            variant="light"
                            @click="openModalCompanyMonthly(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-calendar-check-o"></i>
                        </b-button>
                        <b-button
                            title="Log(s) Funcionário(s)"
                            size="sm"
                            variant="light"
                            @click="openModalLog(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-tasks"></i>
                        </b-button>
                         <b-button
                            title="Log(s) Período(s)"
                            size="sm"
                            variant="light"
                            @click="openModalLogMonth(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-tasks"></i>
                        </b-button>
                    </template>
                </b-table>
            </transition>
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="rows"
            >
            </b-pagination>
        </b-row>
        <b-modal
            id="modalCompanyEmployeeAdmin"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            title="Funcionário(s)"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
             <transition name="slide" type="animation" appear>
                <b-table
                    class="table-responsive"
                    hover
                    key="slide"
                    head-variant="light"
                    :items="company_employees"
                    :fields="fieldsEmployee"
                >
                    <template v-slot:cell(actions)="data">
                        <b-button
                            title="Editar"
                            size="sm"
                            variant="light"
                            @click="openModalCompanyEmployeeRegister(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-pencil"></i>
                        </b-button>
                    </template>
                </b-table>
            </transition>
        </b-modal>
         <b-modal
            id="modalCompanyEmployeeRegister"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            title="Registro Funcionário(s)"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
             @ok="saveCompanyEmployee"
            >
            <b-form>
                <b-row>
                    <b-col md="6" sm="12">
                        <b-form-group
                            label="CPF:"
                            label-for="company_employee-cpf_employee"
                        >
                            <b-form-input
                                id="company_employee-cpf_employee"
                                type="text"
                                v-model="company_employee.cpf_employee"
                                required
                                readonly="true"
                                size="sm"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12">
                        <b-form-group
                            label="Nome:"
                            label-for="company_employee-name_employee"
                        >
                            <b-form-input
                                id="company_employee-name_employee"
                                type="text"
                                v-model="company_employee.name_employee"
                                required
                                readonly="true"
                                size="sm"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                     <b-col md="3" sm="12">
                        <b-form-group
                            label="Pasta de Integração:"
                            label-for="company_employee-return_folder_code"
                        >
                            <b-form-input
                                id="company_employee-return_folder_code"
                                type="text"
                                v-model="company_employee.return_folder_code"
                                required
                                size="sm"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group
                            label="Nº Solicitação:"
                            label-for="company_employee-return_preview_code"
                        >
                            <b-form-input
                                id="company_employee-return_preview_code"
                                type="text"
                                v-model="company_employee.return_preview_code"
                                required
                                size="sm"
                            />
                        </b-form-group>
                    </b-col>
                     <b-col md="3" sm="12">
                        <b-form-group
                            label="Status:"
                            label-for="company_employee-status_description"
                        >
                            <b-form-input
                                id="company_employee-status_description"
                                type="text"
                                v-model="company_employee.status_description"
                                required
                                readonly="true"
                                size="sm"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="company_employee.responsible"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                id="company_employee-responsible"
                                :disabled="true"
                            />
                            <label class="form-check-label" for="company_employee-responsible">
                                Responsável?
                            </label>
                        </div>
                    </div>
                </b-col>
                </b-row>
            </b-form>
        </b-modal>
         <b-modal
            id="modalCompanyMonthlyRegister"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            title="Registro Período(s)"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
             @ok="saveCompanyEmployee"
            >

         </b-modal>
          <b-modal
            id="modalLog"
            ref="modal"
            title-tag="h6"
            centered
            ok-only
            size="lg"
            title="Log(s)"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            >
            
            <FormLog :filter ="setFilter"/>
         </b-modal>
         <b-modal
            id="modalCompanyMonthlyAdmin"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            title="Mensal"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
        >
         <transition name="slide" type="animation" appear>
                <b-table
                    class="table-responsive"
                    hover
                    key="slide"
                    head-variant="light"
                    :items="company_document_monthlys"
                    :fields="fieldMonthly"
                >
                    <template v-slot:cell(actions)="data">
                        <b-button
                            title="Editar"
                            size="sm"
                            variant="light"
                            @click="openModalCompanyMonthlyRegister(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-pencil"></i>
                        </b-button>
                         <b-button
                            title="Remover"
                            size="sm"
                            variant="light"
                            @click="showMsgBoxRemoveMonth(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-trash-o"></i>
                        </b-button>
                    </template>
                </b-table>
            </transition>
        </b-modal>

         <b-modal
            id="modalCompanyAdmin"
            ref="modal"
            title-tag="h6"
            centered
            size="lg"
            title="Registro Empreendimento"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            @ok="saveCompany"
        >
            <div>
                <b-form>
                      <b-row>
                            <b-col md="6" sm="12">
                                <b-form-group
                                    label="Razão Social:"
                                    label-for="company-name_company"
                                >
                                    <b-form-input
                                        id="company-name_company"
                                        type="text"
                                        v-model="company.name_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-group
                                    label="Empreendimento:"
                                    label-for="company-name_build_company"
                                >
                                    <b-form-input
                                        id="company-name_build_company"
                                        type="text"
                                        v-model="company.name_build_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                      </b-row>
                       <b-row>
                            <b-col md="6" sm="12">
                                <b-form-group
                                    label="Nome Fantasia:"
                                    label-for="company-fantasy_company"
                                >
                                    <b-form-input
                                        id="company-fantasy_company"
                                        type="text"
                                        v-model="company.fantasy_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-group
                                    label="CNPJ:"
                                    label-for="company-cnpj_company"
                                >
                                    <b-form-input
                                        id="company-cnpj_company"
                                        type="text"
                                        v-model="company.cnpj_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                      </b-row>
                       <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="Código:"
                                    label-for="company-code_company"
                                >
                                    <b-form-input
                                        id="company-code_company"
                                        type="text"
                                        v-model="company.code_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="Nº Contrato:"
                                    label-for="company-contact_number_company"
                                >
                                    <b-form-input
                                        id="company-contact_number_company"
                                        type="text"
                                        v-model="company.contact_number_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                             <b-col md="3" sm="12">
                                <b-form-group
                                    label="Data Início:"
                                    label-for="company-start_date_company"
                                >
                                    <b-form-input
                                        id="company-start_date_company"
                                        type="date"
                                        v-model="company.start_date_company"
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                             <b-col md="3" sm="12">
                                <b-form-group
                                    label="Data Termino:"
                                    label-for="company-end_date_company"
                                >
                                    <b-form-input
                                        id="company-end_date_company"
                                        type="date"
                                        v-model="company.end_date_company"
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                      </b-row>
                       <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="Coligada:"
                                    label-for="company-code_coligada_company"
                                >
                                    <b-form-input
                                        id="company-code_coligada_company"
                                        type="text"
                                        v-model="company.code_coligada_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="ID PRJ:"
                                    label-for="company-code_id_prj_company"
                                >
                                    <b-form-input
                                        id="company-code_id_prj_company"
                                        type="text"
                                        v-model="company.code_id_prj_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                             <b-col md="3" sm="12">
                                <b-form-group
                                    label="ID CNT:"
                                    label-for="company-code_id_cnt_company"
                                >
                                    <b-form-input
                                        id="company-code_id_cnt_company"
                                        type="text"
                                        v-model="company.code_id_cnt_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-form-group
                                    label="PRJ:"
                                    label-for="company-code_prj_company"
                                >
                                    <b-form-input
                                        id="company-code_prj_company"
                                        type="text"
                                        v-model="company.code_prj_company"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                            </b-col>
                    </b-row>
                    <b-row>
                         <b-col md="3" sm="12">
                                <b-form-group
                                    label="Pasta Integração Funcionário:"
                                    label-for="company-parent_document_id"
                                >
                                    <b-form-input
                                        id="company-parent_document_id"
                                        type="text"
                                        v-model="company.parent_document_id"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                        </b-col>
                        <b-col md="3" sm="12">
                                <b-form-group
                                    label="Pasta Integração Mensal:"
                                    label-for="company-parent_monthly_id"
                                >
                                    <b-form-input
                                        id="company-parent_monthly_id"
                                        type="text"
                                        v-model="company.parent_monthly_id"
                                        required
                                        size="sm"
                                    />
                                </b-form-group>
                        </b-col>
                         <b-col md="3" sm="12">
                                <b-form-group
                                    label="Estado:"
                                    label-for="company-state_company"
                                >
                                    <b-form-input
                                        id="company-state_company"
                                        type="text"
                                        v-model="company.state_company"
                                        required
                                        size="sm"
             
                                    />
                                </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>

import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import { mask } from 'vue-the-mask'
import FormLog from './../util/FormLog'  
var moment = require('moment')

export default {
    name: 'companyAdmin',
    components: {FormLog},
    directives: { mask, moment },
    data: function() {
        return {
            mode: 'insert',
            company: {},
            setFilter:{},
            company_employee: {},
            company_document_monthly: {},
            companys: [],
            company_employees: [],
            company_document_monthlys: [],
            users:[],
            user_id: null,
            search: '',
            showTable: false,
            showSearch: true,
            isBusy: false,
             headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
            currentPage: 1,
            perPage: 5,
            fields: [
                { key: 'id', label: 'Código', sortable: true },
                { key: 'name_company', label: 'Razão Social', sortable: true },
                {
                    key: 'name_build_company',
                    label: 'Empreendimento',
                    sortable: false
                }, 
                {
                    key: 'contact_number_company',
                    label: 'Nº Contrato',
                    sortable: false
                },
                {
                    key: 'blocked_company',
                    label: 'Bloqueada',
                    sortable: false,
                    formatter: value => (value ? 'Sim' : 'Não')
                },
                { key: 'actions', label: 'Ações', variant: 'secondary' }
            ],
               fieldsEmployee: [
                {
                    key: 'name_employee',
                    label: 'Nome Funcionário',
                    sortable: false
                },
                {
                    key: 'cpf_employee',
                    label: 'CPF',
                    sortable: false
                },
                {
                    key: 'return_folder_code',
                    label: 'Pasta de Integração',
                    sortable: false
                },
                {
                    key: 'return_preview_code',
                    label: 'Nº Solicitação',
                    sortable: false
                },
                  {
                    key: 'staus_id_employee',
                    label: 'Status',
                    sortable: false,
                      formatter: value => this.status_employee(value)
                },
              //  { key: 'actions', label: 'Ações', variant: 'light' },
                 { key: 'actions', label: 'Ações', variant: 'secondary' }
            ],
             fieldMonthly:[
                {
                    key: 'description_monthly',
                    label: 'Período entre',
                    sortable: false
                },
                {
                    key: 'parent_doc_monthly_id',
                    label: 'Pasta de Integração',
                    sortable: false
                },
                {
                    key: 'return_preview_code',
                    label: 'Nº Solicitação',
                    sortable: false
                },
                {
                    key: 'status_monthly',
                    label: 'Status',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'secondary'}
            ],
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        filteredList() {
            return this.companys.filter(company => {
                return company.name_build_company
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
            })
        },
        icon() {
            return this.showTable ? 'fa-retweet' : 'fa-search'
        },
        rows() {
            return this.companys.length
        }
    },
    methods: {
          showMsgBoxRemoveMonth(item) {
            this.boxCancel = ''
            this.$bvModal
            .msgBoxConfirm('Deseja remover o registro ?', {
                title: 'Por Favor Confirme',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'SIM',
                cancelTitle: 'NÃO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async value => {
                this.boxCancel = value
                if (this.boxCancel === true) {
                    this.file_upload_table = true    
                    await this.removeMonth(item)
    
                }
            })
        },
        formatDate(date) {
            return moment.utc(date).format('YYYY-MM-DD')
        },
        status_employee(value) {
            if (value === 0) {
                return 'ABERTO'
            } else if (value === 1) {
                return 'EM ANDAMENTO'
            } else if (value === 2) {
                return 'REJEITADO'
            } else if (value === 3) {
                return 'CONCLUÍDA'
            }else if (value === 4) {
                return 'CANCELADO'
            }
        },
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        loadCompanys() {
            const url = `${baseApiUrl}/companys/${this.user_id}/user`
            axios.get(url).then(res => {
                this.companys = res.data
                this.isBusy = false
            })
        },
        loadCompanyEmployees(){

             const url = `${baseApiUrl}/companyemployees/${this.company.id}/user/?status_employee_id=null`
             axios(url).then(res => {
                this.company_employees = res.data
                  
            })
        },
        loadUsers() {
            const url = `${baseApiUrl}/users`
            axios.get(url).then(res => {
                this.users = res.data.map(user =>{
                     return {
                        value: user.id,
                        text: user.email
                    }
                })
            })
        },
        alterModo() {
            this.mode = 'save'
            this.company = {}
            this.$refs.searchFocus.focus()
        },
        loadcompany(item) {
            this.company = { ...item }
        },

        //OpenModal
        openModalCompany(item) {
             this.company = {
                        ...item,
                        start_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD'),
                        end_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD')
                    }

            this.$bvModal.show('modalCompanyAdmin')
        },
        openModalCompanyEmployeeRegister(item) {
             this.company_employee = {...item,}

            this.$bvModal.show('modalCompanyEmployeeRegister')
        },
        openModalLog(item) {
             this.setFilter = {id:item.id,namelog:"employee"}

            this.$bvModal.show('modalLog')
        },
         openModalLogMonth(item) {
             this.setFilter = {id:item.id,namelog:"monthly"}

            this.$bvModal.show('modalLog')
        },
        openModalCompanyMonthlyRegister(item) {
             this.company_document_monthly = {...item,}

            this.$bvModal.show('modalCompanyMonthlyRegister')
        },
       async openModalCompanyEmployee(item) {
             this.company = {
                        ...item,
                        start_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD'),
                        end_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD')
                    }

       
            const url = `${baseApiUrl}/companyemployees/${this.company.id}/user/?status_employee_id=null`
            await  axios(url).then(res => {
                this.company_employees = res.data
                  
            })
              .finally( () => {
                       this.$bvModal.show('modalCompanyEmployeeAdmin')
                })
           
        },
       async openModalCompanyMonthly(item) {
             this.company = {
                        ...item,
                        start_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD'),
                        end_date_company: moment
                            .utc()
                            .local()
                            .format('YYYY-MM-DD')
                    }

            const url = `${baseApiUrl}/companydocumentmonthly/${this.company.id}/company`
            await  axios(url).then(res => {
                this.company_document_monthlys = res.data
                  
            })
              .finally( () => {
                      this.$bvModal.show('modalCompanyMonthlyAdmin')
                })
        },

        //OpenModal end
        

        //Save

         async saveCompany() {
            const method = this.company.id ? 'put' : 'post'
            const id = this.company.id
                ? `/${this.company.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companys${id}`,
                this.company
            )
                .then( () => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanys()
                })
                .catch(showError)
        },
           async saveCompanyEmployee() {
            const method = this.company_employee.id ? 'put' : 'post'
            const id = this.company_employee.id
                ? `/${this.company_employee.id}`
                : ''
            await axios[method](
                `${baseApiUrl}/companyemployees${id}`,
                this.company_employee
            )
                .then( () => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCompanyEmployees()
                })
                .catch(showError)
        },
       async removeMonth(item) {

             const id = item.id
            axios
                .delete(`${baseApiUrl}/companydocumentmonthly/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                })
                .catch(showError)


                const url = `${baseApiUrl}/companydocumentmonthly/${this.company.id}/company`
                await  axios(url).then(res => {
                this.company_document_monthlys = res.data

                })

        }

        //Save end
    },
    mounted() {
        this.loadUsers()

        this.showTable = true
    }
}
</script>

<style>
.company-admin select {
    font-size: 0.75rem;
}
.company-admin input {
    font-size: 0.75rem;
}
.company-admin button {
    font-size: 0.75rem;
}
.company-admin table {
    font-size: 0.75rem;
}

#modalCompanyAdmin button {
     font-size: 0.75rem;
}

#modalCompanyAdmin label,input {
     font-size: 0.75rem;
}

#modalCompanyAdmin input {
     font-size: 0.75rem;
}


#modalCompanyEmployeeAdmin button {
     font-size: 0.75rem;
}

#modalCompanyEmployeeAdmin label {
     font-size: 0.75rem;
}

#modalCompanyEmployeeAdmin input {
     font-size: 0.75rem;
}

#modalCompanyEmployeeAdmin table {
     font-size: 0.75rem;
}

#modalCompanyMonthlyAdmin button {
     font-size: 0.75rem;
}

#modalCompanyMonthlyAdmin label {
     font-size: 0.75rem;
}

#modalCompanyMonthlyAdmin input {
     font-size: 0.75rem;
}

#modalCompanyMonthlyAdmin table {
     font-size: 0.75rem;
}

#modalCompanyEmployeeRegister button {
    font-size: 0.75rem;
}

#modalCompanyEmployeeRegister label {
    font-size: 0.75rem;
}

#modalCompanyEmployeeRegister input {
    font-size: 0.75rem;
}

#modalLog {
     font-size: 0.75rem;
}

#modalLog button {
     font-size: 0.75rem;
}


#modalLog table {
     font-size: 0.75rem;
}


@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
