<template>
    <div class="accordion" role="tablist">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button @click="loadCompanyEmployees"  block v-b-toggle.accordion-1 variant="dark">{{ company.name_build_company }} - {{company.contact_number_company}} </b-button>
        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ul>
                    <li v-for="company_employee in company_employees" :key="company_employee.id"  >
                        <keep-alive>    
                        <ListEmployee :company_employee= "company_employee"/>
                        </keep-alive>
                    </li>
                </ul>
            </b-card-body>
        </b-collapse>
      </b-card-header>
        
    </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'
import ListEmployee from './../util/ListEmployee'  

export default {
    name: 'ListCompany',
    components: {ListEmployee},
    props: ['company'],
    data: function() {
        return {
            company_employees: [],
        
        }
    },
    methods: {
      async  loadCompanyEmployees() {
          const url = `${baseApiUrl}/companyemployeesreject/${this.company.id}`
            await axios(url).then(res => {
                this.company_employees = res.data
            })
        },

    },
    mounted() {
        this.loadCompanyEmployees()
    }
}
</script>

<style>
.accordion {
    font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}

.accordion ul {
    font-size: 0.75rem;
    list-style-type: none;
}
.accordion input {
    font-size: 0.75rem;
}

</style>
