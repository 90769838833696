<template>
    <div class="type_responsable-admin">
        <b-form
            v-show="mode === 'save' || mode === 'remove' || mode === 'read'"
        >
            <input
                id="type_responsable-id"
                type="hidden"
                v-model="type_responsable.id"
            />
            <b-row>
                <b-col md="5" sm="12">
                    <b-form-group
                        label="Descrição:"
                        label-for="type_responsable-name_type_responsable"
                    >
                        <b-form-input
                            id="type_responsable-name_type_responsable"
                            type="text"
                            v-model="type_responsable.name_type_responsable"
                            ref="searchFocus"
                            size="sm"
                            :readonly="
                                mode === 'remove' ||
                                    mode === 'read' ||
                                    mode === 'insert'
                            "
                            placeholder="Descrição..."
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="12">
                    <b-form-group
                        label="Código de Integração:"
                        label-for="type_responsable-code_type_responsable"
                    >
                        <b-form-input
                            id="type_responsable-code_type_responsable"
                            type="text"
                            v-model="type_responsable.code_type_responsable"
                            required
                            size="sm"
                            :readonly="
                                mode === 'remove' ||
                                    mode === 'read' ||
                                    mode === 'insert'
                            "
                            placeholder="Código de Integração..."
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
        <b-row>
            <b-col xs="12">
                <b-button
                    title="Adicionar"
                    size="sm"
                    class="mt-2 mb-2 fa fa-plus"
                    variant="dark"
                    v-show="mode !== 'save' && mode !== 'remove'"
                    @click="alterModo"
                ></b-button>
                <b-button
                    title="Salvar"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-save"
                    v-if="mode === 'save'"
                    @click="save"
                ></b-button>
                <b-button
                    title="Excluir"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-trash"
                    v-if="mode === 'remove'"
                    @click="remove"
                ></b-button>
                <b-button
                    title="Voltar"
                    size="sm"
                    variant="dark"
                    class="mt-2 mb-2 ml-2 fa fa-reply"
                    v-if="
                        mode === 'remove' || mode === 'save' || mode === 'read'
                    "
                    @click="reset"
                ></b-button>
            </b-col>
        </b-row>
        <b-row v-show="mode !== 'save' && mode !== 'remove'">
            <div class="col-sm-6">
                <b-form-group>
                    <input
                        size="lg"
                        type="text"
                        icon="search"
                        v-model="search"
                        placeholder="Informe a descrição"
                        class="form-control"
                    />
                </b-form-group>
            </div>
            <transition name="slide" type="animation" appear>
                <b-table
                    :busy="isBusy"
                    class="table-responsive"
                    hover
                    key="slide"
                    head-variant="light"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="filteredList"
                    :fields="fields"
                >
                    <template v-slot:table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Carregando...</strong>
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button
                            title="Editar"
                            size="sm"
                            variant="light"
                            @click="loadtype_responsable(data.item)"
                            class="mr-2 mt-2"
                        >
                            <i class="fa fa-pencil"></i>
                        </b-button>
                    </template>
                </b-table>
            </transition>
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="rows"
            >
            </b-pagination>
        </b-row>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'TypeResponsable',

    data: function() {
        return {
            mode: 'insert',
            type_responsable: {},
            type_responsables: [],
            search: '',
            showTable: false,
            showSearch: true,
            isBusy: false,
            currentPage: 1,
            perPage: 5,
            fields: [
                { key: 'id', label: 'Código', sortable: true },
                {
                    key: 'name_type_responsable',
                    label: 'Descrição',
                    sortable: true
                },
                {
                    key: 'code_type_responsable',
                    label: 'Código Integração',
                    sortable: false
                },
                { key: 'actions', label: 'Ações', variant: 'secondary' }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        filteredList() {
            return this.type_responsables.filter(type_responsable => {
                return type_responsable.name_type_responsable
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
            })
        },
        icon() {
            return this.showTable ? 'fa-retweet' : 'fa-search'
        },
        rows() {
            return this.type_responsables.length
        }
    },
    methods: {
        toggleBusy() {
            this.isBusy = !this.isBusy
        },
        loadTypeDocuments() {
            const url = `${baseApiUrl}/typeresponsables`
            axios.get(url).then(res => {
                this.type_responsables = res.data
                this.isBusy = false
            })
        },
        alterModo() {
            this.mode = 'save'
            this.type_responsable = {}
            this.$refs.searchFocus.focus()
        },
        save() {
            const method = this.type_responsable.id ? 'put' : 'post'
            const id = this.type_responsable.id ? `/${this.type_responsable.id}` : ''
            axios[method](
                `${baseApiUrl}/typeresponsables${id}`,
                this.type_responsable
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        reset() {
            this.mode = 'insert'
            this.loadTypeDocuments()
        },
        remove() {
            const id = this.type_responsable.id
            axios
                .delete(`${baseApiUrl}/typeresponsables/${id}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        loadtype_responsable(type_responsable, mode = 'save') {
            this.mode = mode
            this.type_responsable = { ...type_responsable }
            this.$refs.searchFocus.focus()
        },

    },
    mounted() {
        this.loadTypeDocuments()
        this.showTable = true
    }
}
</script>

<style>
.type_responsable-admin select {
    font-size: 0.75rem;
}
.type_responsable-admin input {
    font-size: 0.75rem;
}
.type_responsable-admin textarea {
    font-size: 0.75rem;
}
.type_responsable-admin button {
    font-size: 0.75rem;
}
.type_responsable-admin table {
    font-size: 0.75rem;
}

@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
