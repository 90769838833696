<template>
    <div class="accordion" role="tablist">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button    v-b-toggle.accordion-1 variant="ligth"> <strong >  {{ company_monthly.description_monthly }}</strong>   </b-button>     
        <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
            <b-card-body>
                 <keep-alive>   
                         <ListMonthlyFile :company_doc_monthly_id="company_monthly.id" />
                 </keep-alive>
            </b-card-body>
        </b-collapse>
      </b-card-header>
        
    </div>
</template>

<script>
import { baseApiUrl,showError } from '@/global'
import axios from 'axios'
import ListMonthlyFile from './../util/ListMonthlyFile'  

export default {
    name: 'ListMonthly',
    components: {ListMonthlyFile},
    props: ['company_monthly'],
    data: function() {
        return {
            company_monthlys: [],
            company_monthly_id: null
        }
    },
    methods: {
      async  loadCompanyEmployees() {
          const url = `${baseApiUrl}/companydocumentmonthly/${this.company_monthly.id}`
            await axios(url).then(res => {
                this.company_monthlys = res.data
            })
        },
      async changeFile() {           

                  await axios['post'](
                `${baseApiUrl}/companyemployeefileschange/${this.company_monthly.id}`
            )
                .then(() => {
                    this.$toasted.global.defaultSuccess()                
                })
                .catch(showError)
        }
    },
    async mounted() {
       await this.loadCompanyEmployees()
    }
}
</script>

<style>
.accordion {
    font-size: 0.75rem;
}

.accordion button {
    font-size: 0.75rem;
}

.accordion select {
    font-size: 0.75rem;
}

.accordion ul {
    font-size: 0.75rem;
    list-style-type: none;
}

#modalGroupEntity {
    font-size: 0.75rem;
}

#modalGroupEntity button {
    font-size: 0.75rem;
}

#modalGroupEntity select {
    font-size: 0.75rem;
}

@keyframes slide-in {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(40px);
    }
}

.slide-enter-active {
    animation: slide-in 1s ease;
    transition: opacity 1s;
}

.slide-leave-active {
    animation: slide-out 1s ease;
    transition: opacity 1s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
